import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IHttpParams } from 'services/common/Http'
import { IFaqRaw, ICategoryResponse } from 'types/DTO/faq'

export const state = () => ({
  list: [] as IFaqRaw[],
  categories: [] as ICategoryResponse[],
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setList(state, payload) {
    state.list = payload
  },
  setCategories(state, payload) {
    state.categories = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async getFaqList({ commit }, params: IHttpParams) {
    try {
      const res = await this.$repositories.faq.findAll(params)
      commit('setList', res.data.data)
    } catch (e) {
      console.log('error in getFaqList', e)
    }
  },

  async getCategories({ commit }, params: IHttpParams) {
    try {
      const res = await this.$repositories.faq.findAllCategories(params)
      const data = this.$services.faq.categories(res.data.data)
      commit('setCategories', data)
    } catch (e) {
      console.log('error in getCategories', e)
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  faq(state) {
    return state.list
  },
  categories(state) {
    return state.categories
  },
}
