import { IHttp, IHttpParams } from 'services/common/Http'
import { CommonResponse } from 'types/DTO/common/response'
import { IEntityReview, IEntityReviewFull, IReview } from 'types/DTO/reviews'
import { res } from '~/mocks/reviews'
import { IPharmacy } from 'types/DTO/pharmacies'

export interface IPharmacies {
  findAll(params: IHttpParams): Promise<any>

  getReviews(params: IHttpParams): Promise<any>

  findBySlug(slug: string, params: IHttpParams): Promise<any>

  setReview(slug: string, params: IReview): Promise<any>

  findByQuery(query: string, params: IHttpParams): Promise<any>

  getPharmBusinnessTypes(): Promise<any>
}

export default class Pharmacies implements IPharmacies {
  constructor(private http: IHttp) {}

  async findAll(params: IHttpParams): Promise<CommonResponse<IPharmacy[]>> {
    // return await new Promise((resolve) => resolve(object.pharmacies))
    return await this.http.get('/api-v2/pharmacies', params)
  }

  async getPharmBusinnessTypes(): Promise<CommonResponse<IPharmacy[]>> {
    return await this.http.get('/api-v2/pharmacies-business-types', {
      params: {
        'filter[has_pharmacies]': 1,
      },
    })
  }

  async getReviews(params: IHttpParams): Promise<IEntityReviewFull[]> {
    /* return await this.http.get(`/pharmacies/${slug}/reviews`, params || {}) */
    // return await this.http.get('/review-api', params || {})
    return await new Promise((resolve) => resolve(res.reviews))
  }

  async findBySlug(
    slug: string,
    params: IHttpParams,
  ): Promise<CommonResponse<IPharmacy>> {
    return await this.http.get(`/api-v2/pharmacies/${slug}`, params || {})
    // return await new Promise((resolve) =>
    //   resolve(object.pharmacies.data.filter((i: IPharmacy) => i.slug === slug)[0])
    // )
  }

  async setReview(slug: string, params: IEntityReview): Promise<any> {
    const formData = new FormData()
    for (const i in params) {
      // @ts-ignore
      formData.append(`Review[${i}]`, params[i as keyof IEntityReview])
    }
    return await this.http.post('/review-api/create/', formData, {})
  }

  async findByQuery(query: string, params: IHttpParams): Promise<any> {
    // return await this.http.get(`api-v2/pharmacy/search/?word=${query}`, params)
  }
}
