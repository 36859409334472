import { Context } from '@nuxt/types'

export default class Sort {
  constructor(private $route: Context['route']) {}

  checkSortInRoute(type: string): boolean {
    if (this.$route.query.sort) {
      return Boolean(
        this.$route.query.sort &&
          this.$route.query.sort.includes(type) &&
          !this.$route.query.sort.includes(`-${type}`),
      )
    } else {
      return false
    }
  }
}
