import { IHttp } from 'services/common/Http'
import { CommonResponse } from 'types/DTO/common/response'
import { INotification } from 'types/DTO/notification/index'

export interface INotificationSubscribe {
  createSubscription(data: INotification): Promise<CommonResponse<any>>
}

export default class NotificationSubscribe implements INotificationSubscribe {
  constructor(private $http: IHttp) {}

  async createSubscription(data: INotification): Promise<CommonResponse<any>> {
    const formData = new FormData()
    for (const i in data) {
      formData.append(`SubscribeProduct[${i}]`, data[i as keyof INotification])
    }

    return await this.$http.post('/subscribe-product-api/create/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}
