import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IContactNotify } from 'types/DTO/contacts/index'

export const state = () => ({
  nameError: '',
  surnameError: '',
  phoneError: '',
  messageError: '',
  emailError: '',
  success: false,
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setNameError(state, payload) {
    state.nameError = payload
  },
  setSurnameError(state, payload) {
    state.surnameError = payload
  },
  setPhoneError(state, payload) {
    state.phoneError = payload
  },
  setMessageError(state, payload) {
    state.messageError = payload
  },
  setEmailError(state, payload) {
    state.emailError = payload
  },
  setSuccess(state, payload) {
    state.success = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async setContact({ commit }, data: IContactNotify) {
    try {
      const res = await this.$repositories.contacts.setContact(data)

      commit('setSuccess', true)
      this.$services.toaster.add({
        type: 'success',
        title: '',
        description: 'Форма успешно отправлена',
      })
    } catch (e: any) {
      commit('setSuccess', false)
      if (e.response.data.errors && e.response.data.errors.name)
        commit('setNameError', e.response.data.errors.name[0])

      if (e.response.data.errors && e.response.data.errors.surname)
        commit('setSurnameNameError', e.response.data.errors.surname[0])

      if (e.response.data.errors && e.response.data.errors.phone)
        commit('setPhoneError', e.response.data.errors.phone[0])

      if (e.response.data.errors && e.response.data.errors.text)
        commit('setMessageError', e.response.data.errors.text[0])

      if (e.response.data.errors && e.response.data.errors.email)
        commit('setEmailError', e.response.data.errors.email[0])

      this.$services.toaster.add({
        type: 'error',
        title: 'Ой-ой! ',
        description:
          'Произошла неизвестная ошибка. Сообщение не отправлено. Попробуйте снова чуть позже.',
      })
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  nameError(state) {
    return state.nameError
  },
  surnameError(state) {
    return state.surnameError
  },
  phoneError(state) {
    return state.phoneError
  },
  messageError(state) {
    return state.messageError
  },
  emailError(state) {
    return state.emailError
  },
  success(state) {
    return state.success
  },
}
