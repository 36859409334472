import { object } from '~/mocks/cards'
import { IHttp, IHttpParams, IHttpResponse } from 'services/common/Http'
import { CommonResponse } from 'types/DTO/common/response'
import { ICard, ICardData } from 'types/DTO/cards'

export interface ICards {
  findAll(params: IHttpParams): Promise<any>
  addCard(data: ICardData, params: IHttpParams): Promise<any>
  deleteCard(id: number, params?: IHttpParams): Promise<any>
  chooseCard(id: number, params?: IHttpParams): Promise<any>
}

export default class Cards implements ICards {
  constructor(private http: IHttp) {}

  async findAll(params: IHttpParams): Promise<CommonResponse<ICard[]>> {
    return await this.http.get('/api-v2/user-cards', params || {})
    // return await new Promise((resolve) => resolve(object.cards))
  }

  async addCard(data: ICardData, params: IHttpParams): Promise<any> {
    const fd = new FormData()
    for (const field in data.attributes) {
      fd.append('type', data.type)
      // @ts-ignore
      fd.append(`CloudCardsUser[${field}]`, data.attributes[field])
    }

    return await this.http.post('/user-api/add-card-cloud/', fd, params)
  }

  async deleteCard(id: number, params: IHttpParams): Promise<CommonResponse<ICard[]>> {
    return await this.http.delete(`/api-v2/user-cards/${id}`)
  }

  async chooseCard(id: number, params: IHttpParams): Promise<any> {
    return await this.http.get(`/user-api/set-default-card-cloud?id=${id}`, params)
  }
}
