export default {
  async getReviews({ commit }, { page, sort }) {
    try {
      const reviewsRes = await this.$axios.$get(`/review-api/`, {
        params: {
          page,
          sort: sort || '',
          // filter: filter || '',
          'per-page': 5,
        },
      })
      commit('SET_REVIEWS', reviewsRes)
    } catch (e) {
      throw new Error(`Ошибка получения отзывов: ${JSON.stringify(e)}`)
    }
  },
  async getReviewsTotal() {
    return await this.$axios.$get('/review-api/get-total/')
  },
  async sendReview({ commit }, payload) {
    const formData = new FormData()

    if (Object.keys(payload).length) {
      for (const field in payload) {
        formData.append(`Review[${field}]`, payload[field])
      }
    }

    return await this.$axios.$post('/review-api/create/', formData)
  },
}
