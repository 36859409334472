import { ActionTree, MutationTree, GetterTree } from 'vuex/types/index'
import { IModal } from 'types/DTO/common/modals'

export const state = () => ({
  reviewForm: {
    show: false,
  } as IModal<any>,
  checkoutModal: {
    show: false,
  } as IModal<any>,
  productConfirm: {
    show: false,
    data: {
      isConfirmed: false,
    },
  } as IModal<any>,
  logoutConfirm: {
    show: false,
  } as IModal<any>,
  pharmacyReport: {
    show: false,
  } as IModal<any>,
  adultConfirm: {
    show: false,
  } as IModal<any>,
})

type stateT = ReturnType<typeof state>

export const getters: GetterTree<stateT, stateT> = {
  reviewShow(state) {
    return state.reviewForm.show
  },
  checkoutShow(state) {
    return state.checkoutModal.show
  },
  adultConfirmModal(state) {
    return state.adultConfirm.show
  },
  productConfirm(state) {
    return state.productConfirm
  },
  productConfirmShow(state) {
    return state.productConfirm.show
  },
  productConfirmed(state) {
    return state.productConfirm.data.isConfirmed
  },
  logoutConfirm(state) {
    return state.logoutConfirm.show
  },
  pharmacyReportShow(state) {
    return state.pharmacyReport.show
  },
}

export const mutations: MutationTree<stateT> = {
  /**
   * Закрытие модалок
   * */
  clearModals(state) {
    state.reviewForm.show = false
    state.checkoutModal.show = false
    state.productConfirm.show = false
    state.logoutConfirm.show = false
    state.pharmacyReport.show = false
    state.adultConfirm.show = false
  },
  setReviewFormModal(state: stateT, payload) {
    state.reviewForm = payload
  },
  setCheckoutModal(state: stateT, payload) {
    state.checkoutModal = payload
  },
  setProductConfirmModal(state: stateT, payload) {
    state.productConfirm = payload
  },
  setLogoutConfirm(state: stateT, payload) {
    state.logoutConfirm = payload
  },
  setPharmacyReportModal(state: stateT, payload) {
    state.pharmacyReport = payload
  },
  setAdultModal(state: stateT, payload) {
    state.adultConfirm.show = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  clearModals({ commit }) {
    commit('clearModals')
  },
  changeReviewFormModal({ commit }, payload: IModal<any>) {
    commit('setReviewFormModal', payload)
  },
  changeCheckoutModal({ commit }, payload: IModal<any>) {
    commit('setCheckoutModal', payload)
  },
  changeAdultModal({ commit }, payload: IModal<any>) {
    commit('setAdultModal', payload)
  },
  changeProductConfirmModal({ commit }, payload: IModal<any>) {
    commit('setProductConfirmModal', payload)
  },
  changeLogoutConfirmModal({ commit }, payload: IModal<any>) {
    commit('setLogoutConfirm', payload)
  },
  changePharmacyReportModal({ commit }, payload: IModal<any>) {
    commit('setPharmacyReportModal', payload)
  },
}
