import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { ICategory } from 'types/DTO/categories'
import { IChildCategory } from 'types/DTO/common/response'
import { IHttpParams } from 'services/common/Http/index'

export const state = () => ({
  categories: [] as ICategory[],
  categoryBySlug: {} as ICategory[],
  childCategoriesBySlug: [] as IChildCategory[],
  isBlurred: false
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setCategories(state, payload) {
    state.categories = payload
  },
  setCategoryBySlug(state, payload) {
    state.categoryBySlug = payload
  },
  setChildCategoriesBySlug(state, payload) {
    state.childCategoriesBySlug = payload
  },
  changeBlur(state, payload) {
    state.isBlurred = payload;
  }
}

export const actions: ActionTree<stateT, stateT> = {
  async getCategories({ commit, state }, params: IHttpParams) {
    try {
      if (state.categories.length) return
      const { data } = await this.$repositories.categories.findAll(params)
      commit('setCategories', data.data)
    } catch (e) {
      console.log('error in getCategories', e)
      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Не удалось загрузить категории',
      })
    }
  },
  async getCategoryBySlug({ commit }, { slug, params }) {
    try {
      const { data } = await this.$repositories.categories.findBySlug(slug, params)
      commit('setCategoryBySlug', data)
      commit('setChildCategoriesBySlug', data.child)
    } catch (e) {
      console.log('error in getCategories', e)
      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Не удалось загрузить категорию',
      })
    }
  },
  async getParentCategoryBySlug({ commit }, { slug, params }) {
    try {
      const { data } = await this.$repositories.categories.findBySlug(slug, params)
      commit('setChildCategoriesBySlug', data.child)
    } catch (e) {
      console.log('error in getCategories', e)
      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Не удалось загрузить категорию',
      })
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  categories(state) {
    return state.categories
  },
  categoryBySlug(state) {
    return state.categoryBySlug
  },
  childCategoriesBySlug(state) {
    return state.childCategoriesBySlug
  },
  isBlurred(state) {
    return state.isBlurred;
  }
}
