import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { IToast } from 'types/DTO/toast/index'

export const state = () => ({
  notifications: [] as IToast[],
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const actions: ActionTree<stateT, stateT> = {}

export const mutations: MutationTree<stateT> = {
  addNotification(state, data: IToast) {
    data.id = Math.floor(Math.random() * (10000 - 1)) + 1
    state.notifications.push(data)
  },
  deleteNotification(state, indexByArray: number) {
    state.notifications.splice(indexByArray, 1)
  },
}

export const getters: GetterTree<stateT, stateT> = {
  notifications(state) {
    return state.notifications
  },
}
