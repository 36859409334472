export default class SearchService {
  constructor(private $store: any) {}

  setSearchCookie(name: string, value: string, days: any) {
    let expires = ''
    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
    }

    // Установка cookie с атрибутами Secure и SameSite
    document.cookie = `${name}=${encodeURIComponent(value || '')}${expires}; path=/; Secure; SameSite=Lax`
  }

  getSearchCookie(name: string) {
    const nameEQ = name + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length))
    }
    return null
  }

  addSearchQuery(query: string) {
    let historyStr: string = this.getSearchCookie('searchHistory') ?? ''
    const history = historyStr ? JSON.parse(historyStr) : []

    if (!history.includes(query)) {
      history.unshift(query)
    }

    // Ограничение длины истории до 3 записей
    if (history.length > 3) {
      history.pop()
    }

    this.setSearchCookie('searchHistory', JSON.stringify(history), 7)
  }

  removeSearchHistory() {
    this.setSearchCookie('searchHistory', JSON.stringify([]), 7)
  }

  showSearchHistory() {
    const history = this.getSearchCookie('searchHistory') ?? ''
    return history ? JSON.parse(history) : []
  }
}
