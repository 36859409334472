import { CommonResponse } from 'types/DTO/common/response'
import { IHttp, IHttpParams, IHttpResponse } from 'services/common/Http'
import { ISocialsRaw } from 'types/DTO/socials'

export interface IFooter {
  findSettings(params: IHttpParams): Promise<IHttpResponse<CommonResponse<any>>>
}

export default class Footer implements IFooter {
  constructor(private $http: IHttp) {}

  async findSettings(
    params: IHttpParams
  ): Promise<IHttpResponse<CommonResponse<ISocialsRaw>>> {
    return await this.$http.get('/settings-api', {
      params: {
        ...params,
        'per-page': 50,
      },
    })
  }
}
