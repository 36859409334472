import { IHttp, IHttpParams, IHttpResponse } from 'services/common/Http'
import { CommonResponse } from 'types/DTO/common/response'
import { IInn } from 'types/DTO/inns'
import { object } from '~/mocks/inns/index'

export interface IInns {
  findAll(params: IHttpParams): Promise<CommonResponse<IInn[]>>
  findBySlug(slug: string, params: IHttpParams): Promise<CommonResponse<IInn>>
}

export default class Inns implements IInns {
  constructor(private http: IHttp) {}

  async findAll(params: IHttpParams): Promise<CommonResponse<IInn[]>> {
    return await this.http.get('/api-v2/inns', params)
    // return await new Promise((resolve) => resolve(object.inns))
  }

  async findBySlug(slug: string, params: IHttpParams): Promise<CommonResponse<IInn>> {
    return await this.http.get(`/api-v2/inns/${slug}`, params || {})
    // return await new Promise((resolve) =>
    //   resolve(object.inns.data.filter((i: IInn) => i.slug === slug)[0])
    // )
  }
}
