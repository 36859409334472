export default ({ app }, inject) => {
  inject('throttle', (fn, delay) => {
    let timeout = null
    return function (...args) {
      if (timeout) return
      timeout = setTimeout(() => {
        fn(...args)
        timeout = null
      }, delay)
    }
  })
}
