import { CommonResponse } from 'types/DTO/common/response'
import { IHttp, IHttpParams, IHttpResponse } from 'services/common/Http'
import { IContactNotify } from 'types/DTO/contacts'
import { contacts } from '~/mocks/contacts'

export interface IContacts {
  findAll(params: IHttpParams): Promise<IHttpResponse<CommonResponse<any[]>>>
  findContactsDetails(field: string, params: IHttpParams): Promise<any>
  setContact(data: IContactNotify): Promise<any>
}

export default class Contacts implements IContacts {
  constructor(private $http: IHttp) {}

  async findAll(params: IHttpParams): Promise<IHttpResponse<CommonResponse<any[]>>> {
    return await this.$http.get('/api-v2/cities', params)
  }

  async findContactsDetails(field: string, params: IHttpParams): Promise<any> {
    /* return await this.$http.get('/settings-api/', {
      params: {
        ...params,
        'filter[param][like]': field,
      },
    }) */
    return await new Promise((resolve) => resolve(contacts))
  }

  async setContact(data: IContactNotify): Promise<any> {
    const formData = new FormData()
    for (const i in data) {
      formData.append(`Contact[${i}]`, data[i as keyof IContactNotify])
    }
    return await this.$http.post('/contact-api/create/', formData)
  }
}
