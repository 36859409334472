import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IEntityReviewFull } from 'types/DTO/reviews'
import { IHttpParams } from 'services/common/Http'

export const state = () => ({
  reviews: [] as IEntityReviewFull[],
  rateError: '',
  contentError: '',
  success: false,
  submitting: false,
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setReviews(state, payload) {
    state.reviews = payload
  },
  setSuccess(state, payload) {
    state.success = payload
  },
  setRateError(state, payload) {
    state.rateError = payload
  },
  setContentError(state, payload) {
    state.contentError = payload
  },
  setSubmit(state, payload) {
    state.submitting = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async getReviews({ commit }, { params }) {
    try {
      const res = await this.$repositories.products.getReviews(params)
      const data = this.$services.reviews.adapt(res.data.data)
      commit('setReviews', data)
    } catch {
      console.log('error: getReviews')
    }
  },

  async setReview({ commit }, { slug, params }) {
    try {
      commit('setSubmit', true)
      await this.$repositories.products.setReview(slug, params)
      commit('setSuccess', true)

      this.$services.toaster.add({
        type: 'success',
        title: '',
        description:
          params.object === 'news'
            ? 'Спасибо, Ваш комментарий отправлен. После модерации он будет опубликован на сайте.'
            : 'Отзыв отправлен успешно',
      })
    } catch (e: any) {
      console.log('error: setReviews', e)
      commit('setSuccess', false)
      if (e.response.data.errors && e.response.data.errors.rate)
        commit('setRateError', e.response.data.errors.rate[0])

      if (e.response.data.errors && e.response.data.errors.text)
        commit('setContentError', e.response.data.errors.text[0])

      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Что-то пошло не так',
      })
    } finally {
      commit('setSubmit', false)
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  reviews(state) {
    return state.reviews
  },
  rateError(state) {
    return state.rateError
  },
  contentError(state) {
    return state.contentError
  },
  reviewsCount(state) {
    return state.reviews.length
  },
  success(state) {
    return state.success
  },
  submitting(state) {
    return state.submitting
  },
}
