export interface INumberFormat {
  number(num: number): string
  formatDate(currentDate: string): string
  formatDateIncline(currentDate: string): string
  formatDateToDMY(currentDate: string): string
  getWeekDay(number: number): string
}

export default class NumberFormat implements INumberFormat {
  private _intl = new Intl.NumberFormat('ru-RU')

  number(num: number): string {
    return this._intl.format(num)
  }

  /**
   * @name formatDate
   * @description Return format day name of the month year. Accepts format D.M.Y
   * @param {string} currentDate
   * @return {string}
   */
  formatDate(currentDate: string): string {
    const months = [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ]

    const [day, month, year] = currentDate.split('.')

    return `${day} ${months[+month - 1]} ${year}`
  }

  /**
   * @name formatDateToDMY
   * @description Return format dd-mm-yyyy. Accepts format yyyy-mm-dd
   * @param {string} currentDate
   * @return {string}
   */
  formatDateToDMY(currentDate: string) {
    return currentDate.split('-').reverse().join('.')
  }

  /**
   * @name formatDateIncline
   * @description Return format day name of the month year. Accepts format Y-M-D
   * @param {string} currentDate
   * @return {string}
   */
  formatDateIncline(currentDate: string) {
    const [year, month, day] = currentDate.split(' ')[0].split('-')
    // @ts-ignore
    return new Date(year, month - 1, day)
      .toLocaleString('ru', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
      .replace(/\s*г\./, '')
  }

  /**
   * @name getWeekDay
   * @description Returns week day
   * @param {string} number
   * @return {string}
   */
  getWeekDay(number: number): string {
    const daysOfWeek = [
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
      'Воскресенье',
    ]

    return daysOfWeek[number - 1]
  }
}
