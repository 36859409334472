import { IHttpParams, IHttp, IHttpResponse } from 'services/common/Http'
import { CommonResponse } from 'types/DTO/common/response'
import { IBanner } from 'types/DTO/banners'
import { all } from '~/mocks/banners'

export interface IBanners {
  findAll(params: IHttpParams): Promise<CommonResponse<IBanner[]>>

  findSmallBanners(params: IHttpParams): Promise<IHttpResponse<CommonResponse<IBanner[]>>>

  findLargeBanners(params: IHttpParams): Promise<IHttpResponse<CommonResponse<IBanner[]>>>

  findHotBanners(params: IHttpParams): Promise<any>
}

export default class Banners implements IBanners {
  constructor(private $http: IHttp) {}

  async findAll(params: IHttpParams): Promise<CommonResponse<IBanner[]>> {
    return await new Promise((resolve) => resolve(all))
  }

  async findSmallBanners(
    params?: IHttpParams,
  ): Promise<IHttpResponse<CommonResponse<IBanner[]>>> {
    return await this.$http.get('/api-v2/banners', {
      params: {
        'filter[type]': 'slider',
        // 'per-page': 7,
      },
    })
  }

  async findLargeBanners(
    params?: IHttpParams,
  ): Promise<IHttpResponse<CommonResponse<IBanner[]>>> {
    return await this.$http.get('/api-v2/banners', {
      params: {
        'filter[type]': 'banner',
        'per-page': 4,
      },
    })
  }

  async findHotBanners(
    params?: IHttpParams,
  ): Promise<IHttpResponse<CommonResponse<IBanner[]>>> {
    return await this.$http.get('/hot-banners-api', {
      params: {
        'filter[is_active]': 1,
      },
    })
  }
}
