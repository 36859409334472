import { IProduct } from 'types/DTO/products'

export default class ProductAdapter {
  constructor() {}

  adaptItem(item: IProduct): any {
    return {
      id: item.id,
      title: item.short_title,
      mnn: item?.specification.mnn,
      price: item.prices.standard.price,
      cost: item.prices.standard.price,
      'exp-price': item?.prices.hot_price.price,
      'exp-provider-price': item?.prices.hot_price.price,
      'exp-count': item?.prices.hot_price.count,
      'exp-date': item?.prices.hot_price.expires,
      'category-id': item?.category_id,
      'title-long': item?.title,
      'fast-delivery-count': item?.prices.fast_delivery.count,
      'fast-delivery-price': item?.prices.fast_delivery.price,
      count: item?.specification.count,
      'pharmacy-id': item.prices.fast_delivery.pharmacy_id,
      'provider-id': item.prices.standard.price,
      barcode: item?.specification.barcode,
      'company-name': item.producers ? item?.producers.title : '',
      country: item.specification.country,
      'issue-form': item?.specification.issue_form,
      dosage: item?.specification.dosage,
      'box-count': item?.specification.box_count,
      recipe: item?.specification.recipe,
      disabled: false,
    }
  }

  createDataObject(
    product: any,
    priceType: any,
    prices: Array<any>,
    isGuest: number
  ): any {
    return {
      product: {
        ...product,
        cost: prices[priceType.typeOfPrice].price,
        total: prices[priceType.typeOfPrice].price,
        amount: priceType.value,
        is_exp: !!priceType.isReduced,
        virtual: priceType.isReduced || priceType.typeOfPrice === 'standard',
        is_competitor: 0,
        selected_price: priceType.typeOfPrice,
      },
      product_api: {
        product_id: priceType.id,
        count: priceType.value,
        cart_id: priceType.cartId || '',
        is_guest: isGuest,
        is_exp: +!!priceType.isReduced,
        is_competitor: 0,
      },
    }
  }
}
