export default {
  SET_REVIEWS(state, { data, links, meta }) {
    if (data.length) {
      data.forEach((item) => {
        if (item.text && item.text.length > 50) {
          item.openBtn = true
        } else {
          item.openBtn = false
        }
      })
    }

    state.reviewsList = data

    for (const link in links) {
      state.reviewsInfo.links[link] = links[link].href
    }

    for (const m in meta) {
      state.reviewsInfo.meta[m] = meta[m]
    }
  },
  SET_PAGINATION_PAGE(state, payload) {
    state.reviewsInfo.meta['current-page'] = payload
  },
}
