import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { IHttpParams } from 'services/common/Http'
import { IDisease, IDiseasesList, IMetaData } from 'types/DTO/disease/index'

export const state = () => ({
  diseases: {} as IDiseasesList[],
  diseasesMeta: {} as IMetaData,
  diseaseCategories: [] as IDisease[],
  diseaseSingle: {} as IDisease,
  diseasesPageSeo: {},
  diseasesPageSeoText: '',
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const actions: ActionTree<stateT, stateT> = {
  async getDiseaseCategories({ commit }, params: IHttpParams) {
    try {
      const res = await this.$repositories.disease.getDiseaseCategories(params)
      commit('setDiseaseCategories', res.data.data)
    } catch (e) {
      console.log('error in getDiseaseCategories', e)
    }
  },
  async getDiseaseList({ commit }, params: IHttpParams) {
    try {
      const res = await this.$repositories.disease.getDiseaseList(params)
      commit('setDiseaseList', res.data.data)
      commit('setDiseasesMeta', res.data.meta)
    } catch (e) {
      console.log('error in getDisease', e)
    }
  },
  async getDiseaseBySlug({ commit }, { slug, params }) {
    try {
      const res = await this.$repositories.disease.findBySlug(slug, params)
      commit('setDiseaseSingle', res.data)
    } catch (e) {
      console.log('error in getDisease', e)
    }
  },
  async getDiseasesPage({ commit }) {
    try {
      const res = await this.$repositories.content.findContent('bolezni', 0, {})
      commit('setDiseasesPageSeo', res.data.data[0].attributes.seo)
      commit('setDiseasesPageSeoText', res.data.data[0].attributes.text)
    } catch (e) {
      console.error('Error getting disease page content', e)
    }
  },
}
export const mutations: MutationTree<stateT> = {
  setDiseaseCategories(state, payload) {
    state.diseaseCategories = payload
  },
  setDiseaseSingle(state, payload) {
    state.diseaseSingle = payload
  },
  setDiseaseList(state, payload) {
    state.diseases = payload
  },
  setDiseasesMeta(state, payload) {
    state.diseasesMeta = payload
  },
  setDiseasesPageSeo(state, payload) {
    state.diseasesPageSeo = payload
  },
  setDiseasesPageSeoText(state, payload) {
    state.diseasesPageSeoText = payload
  },
}

export const getters: GetterTree<stateT, stateT> = {
  diseaseCategories(state) {
    return state.diseaseCategories
  },
  diseases(state) {
    return state.diseases
  },
  diseasesMeta(state) {
    return state.diseasesMeta
  },
  disease(state) {
    return state.diseaseSingle
  },
  diseasesPageSeo(state) {
    return state.diseasesPageSeo
  },
  diseasesPageSeoText(state) {
    return state.diseasesPageSeoText
  },
}
