import { IHttp, IHttpResponse } from 'services/common/Http'
import axios from 'axios'

export interface IMindboxMethods {
  sendMindboxEvent(operation: string, data: any): any
}

export default class Mindbox implements IMindboxMethods {
  constructor(http: IHttp) {}

  sendMindboxEvent(operation: string, data: any): any {
    if (process.env.NODE_ENV === 'production') {
      // @ts-ignore
      return window.mindbox('sync', {
        operation: `Website.${operation}`,
        data,
        onSuccess: this.mindboxReqSuccess,
        onError: this.mindboxReqError,
      })
    }
  }

  mindboxReqSuccess() {
    console.log('event success')
  }
  mindboxReqError(err: any) {
    console.log(err)
  }
}
