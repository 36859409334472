import { IHttp, IHttpParams, IHttpResponse } from 'services/common/Http'
import { CommonResponse } from 'types/DTO/common/response'
import { IProducer } from 'types/DTO/producers'
import { object } from '~/mocks/producers/index'

export interface IProducers {
  findAll(params: IHttpParams): Promise<CommonResponse<IProducer[]>>
  findBySlug(slug: string, params: IHttpParams): Promise<CommonResponse<IProducer>>
}

export default class Producers implements IProducers {
  constructor(private http: IHttp) {}

  async findAll(params: IHttpParams): Promise<CommonResponse<IProducer[]>> {
    return await this.http.get('/api-v2/producers', params)
    // return await new Promise((resolve) => resolve(object.producers))
  }

  async findBySlug(
    slug: string,
    params: IHttpParams
  ): Promise<CommonResponse<IProducer>> {
    return await this.http.get(`/api-v2/producers/${slug}`, params || {})
    // return await new Promise((resolve) =>
    //   resolve(object.producers.data.filter((i: IProducer) => i.slug === slug)[0])
    // )
  }
}
