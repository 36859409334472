import { extend } from 'vee-validate'
import { required, confirmed, email } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Поле обязательно',
})

extend('requiredSelect', (value) => {
  if (Object.keys(value).length) {
    return true
  }

  return 'Поле обязательно'
})

extend('confirmed', {
  ...confirmed,
  message: 'Пароли не совпадают',
})

extend('email', {
  ...email,
  message: 'Email должен быть корректным',
})

extend('phone', (value) => {
  if (value && value.replace(/\D+/g, '').length === 11) {
    return true
  }

  return 'Телефон должен быть корректным'
})

extend('length', {
  validate(value, args) {
    return value.length === args.length
  },
  params: ['length'],
  message: 'Неверный формат индекса',
})

extend('min', {
  validate(value, args) {
    return value.length >= args.length
  },
  params: ['length'],
  message: 'Длина поля {_field_} должна быть больше {length}',
})

extend('password', {
  validate(value) {
    const pattern = /^[^\sА-Яа-яЁё]+$/
    return pattern.test(value)
  },
  // message: 'Пароль должен содержать спец символы, A-Z, a-z и 0-9, длиной не менее 8 символов',
  message: 'Поле не должно содержать пробелы и кириллические символы.'
})
