import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IProduct, IProductTabs } from 'types/DTO/products'
import { IMetaData } from 'types/DTO/common/response'
import { IHttpParams } from 'services/common/Http'

export const state = () => ({
  popularProducts: [] as IProduct[],
  nearestProducts: [] as IProduct[],
  products: [] as IProduct[],
  product: {} as IProduct,
  annotations: [] as IProductTabs[],
  meta: {} as IMetaData,
  analogs: [] as IProduct[],
  foundProducts: [] as IProduct[],
  searchPopularProducts: [] as IProduct[],
  searchError: false as boolean,
  searchEmpty: false as boolean,
  searchBlockObject: {
    taps: [],
    sts: [],
    categories: [],
  } as any,

  searchPageObject: {
    taps: [],
    sts: [],
    categories: [],
  } as any,
  isAnyQuery: false as boolean,
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setPopularProducts(state, payload) {
    state.popularProducts = payload
  },
  setNearestProducts(state, payload) {
    state.nearestProducts = payload
  },
  setProducts(state, payload) {
    state.products = payload
  },
  setProduct(state, payload) {
    state.product = payload
  },
  setAnalogs(state, payload) {
    state.analogs = payload
  },
  setMeta(state, payload) {
    state.meta = payload
  },
  setAnnotations(state, payload) {
    state.annotations = payload
  },
  setFoundProducts(state, payload) {
    state.foundProducts = payload
  },

  setSearchPopularProducts(state, payload) {
    state.searchPopularProducts = payload
  },

  clearFoundProducts(state) {
    state.foundProducts = []
  },
  setSearchError(state, payload) {
    state.searchError = payload
  },

  setSearchEmpty(state, payload) {
    state.searchEmpty = payload
  },

  setSearchBlockObject(state, payload) {
    state.searchBlockObject = payload
  },

  setSearchPageObject(state, payload) {
    state.searchPageObject = payload
  },

  setIsAnyQuery(state, payload) {
    state.isAnyQuery = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async getPopularProducts({ commit, state }, params: IHttpParams) {
    try {
      if (state.popularProducts.length) return
      const res = await this.$repositories.products.findAll(params)
      commit('setPopularProducts', res.data?.data ?? [])
    } catch {
      console.log('error: getProducts')
    }
  },

  async getNearestProducts({ commit, state }, params: IHttpParams) {
    try {
      if (state.nearestProducts.length) return
      const res = await this.$repositories.products.findAll(params)
      commit('setNearestProducts', res.data.data)
    } catch {
      console.log('error: getProducts')
    }
  },

  async getProducts({ commit }, params: IHttpParams) {
    try {
      const res = await this.$repositories.products.findAll(params)
      commit('setIsAnyQuery', res.headers?.['x-search-source'] === 'AnyQuery')
      commit('setProducts', res.data?.data ?? [])
      // @ts-ignore
      commit('setMeta', res.data?.meta)
    } catch {
      console.log('error: getProducts')
    }
  },

  async getProductAnalogs({ commit }, { slug, params }) {
    try {
      const res = await this.$repositories.products.findAnalogs(slug, params)
      commit('setAnalogs', res.data.products.products)
      // @ts-ignore
      return res.data
    } catch {
      console.log('error: getProductAnalogs')
    }
  },

  async getProductBySlug({ commit }, { slug, category, params }) {
    try {
      const res = await this.$repositories.products.findBySlug(slug, category, params)
      const annotations = this.$services.product.mapDropDownSpecs(res.data.annotation)
      commit('setProduct', res.data)
      commit('setAnnotations', annotations)
      commit('setAnalogs', res.data.analogues[0].products)
    } catch (error) {
      console.log('error: getProductBySlug', error)
    }
  },

  async getSearchBlockProducts({ commit, dispatch }, { searchString, params }) {
    try {
      const res = await this.$repositories.products.searchAutocompleteAll(searchString, params)
      const productsParams = new URLSearchParams({
        expand: 'prices,images,seo,producers,extra',
      })
      productsParams.append('fixed_order', '1')
      if (res.data.products.length) {
        commit('setSearchEmpty', false)
        // @ts-ignore
        res.data?.products.forEach(({ id }) => {
          productsParams.append('filter[id][in][]', id)
        })

        const productsResponse = await this.$repositories.products.findAll({
          params: productsParams,
        })
        if (searchString.length) {
          commit('setFoundProducts', productsResponse.data?.data ?? [])
        } else {
          commit('setSearchPopularProducts', productsResponse.data?.data ?? [])
        }
      } else {
        commit('setSearchEmpty', !!searchString.length)
      }

      commit('setSearchBlockObject', res.data ?? [])
      commit('setSearchError', false)
    } catch {
      await dispatch('getProductsByQuery', {
        params: {
          expand: 'annotation,prices,images,category,seo,specification,similarCount,producers',
          'filter[title]': searchString,
        },
      })
      commit('setSearchError', true)
      console.log('error: getSearchBlockProducts')
    }
  },

  async getSearchPageProducts({ commit }, { searchString, params }) {
    try {
      const res = await this.$repositories.products.getSearchPageProducts(searchString, params)
      const productsParams = new URLSearchParams({
        expand: 'prices,images,seo,producers,extra',
      })
      // productsParams.append('fixed_order', '1')
      if (res.data.products.length) {
        // @ts-ignore
        res.data?.products.forEach(({ id }) => {
          productsParams.append('filter[id][in][]', id)
        })

        const productsResponse = await this.$repositories.products.findAll({
          params: productsParams,
        })
        commit('setProducts', productsResponse.data?.data ?? [])
      }

      commit('setsearchBlockObject', res.data ?? [])
      commit('setSearchError', false)
    } catch {
      commit('setSearchError', true)
      console.log('error: getSearchPageProducts')
    }
  },

  async getProductsByQuery({ commit }, params: IHttpParams) {
    try {
      const res = await this.$repositories.products.findAll(params)

      commit('setFoundProducts', res.data?.data ?? [])
      commit('setSearchError', false)
    } catch {
      commit('setSearchError', true)
      console.log('error: getProductsByQuery')
    }
  },

  async getAutocomplete({ commit }, { searchString, params }) {
    try {
      const res = await this.$repositories.products.searchAutocompleteAll(searchString, params)

      commit('setSearchPageObject', res.data ?? [])
      commit('setSearchError', false)
    } catch {
      commit('setSearchError', true)
      console.log('error: getAutocomplete')
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  popularProducts(state) {
    return state.popularProducts
  },
  nearestProducts(state) {
    return state.nearestProducts
  },
  products(state) {
    return state.products
  },
  analogs(state) {
    return state.analogs
  },
  product(state) {
    return state.product
  },
  meta(state) {
    return state.meta
  },
  annotations(state) {
    return state.annotations
  },
  foundProducts(state) {
    return state.foundProducts
  },

  searchPopularProducts(state) {
    return state.searchPopularProducts
  },
  analogsCount(state) {
    return state.analogs.length
  },

  searchError(state) {
    return state.searchError
  },

  searchEmpty(state) {
    return state.searchEmpty
  },

  searchBlockObject(state) {
    return state.searchBlockObject
  },
  searchPageObject(state) {
    return state.searchPageObject
  },

  isAnyQuery(state) {
    return state.isAnyQuery
  },
}
