import { IGeocoderAddress } from 'types/DTO/geocoder'

export default class GeocoderAdapter {
  constructor() {}

  adapt(data: any): any {
    const addressData = data.GeoObject.metaDataProperty.GeocoderMetaData.Address
    const addressObj = {} as IGeocoderAddress
    addressData.Components.forEach((item: any) => {
      // @ts-ignore
      addressObj[item.kind] = item.name
    })
    addressObj.full = addressData.formatted
    return addressObj
  }

  formatAddress(address: IGeocoderAddress): string {
    const flat = address.flat?.length ? ', кв. ' + address.flat : ''
    return (
      'г. ' +
      address.province +
      ', ' +
      address.street +
      ', ' +
      'д. ' +
      address.house +
      flat
    )
  }
}
