import { MutationTree, ActionTree, GetterTree } from 'vuex'

export const state = () => ({
  isCollapsed: false,
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setCollapsed(state, payload) {
    state.isCollapsed = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {}

export const getters: GetterTree<stateT, stateT> = {
  isCollapsed(state) {
    return state.isCollapsed
  },
}
