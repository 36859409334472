import { Context } from '@nuxt/types'
import { IBasketProduct, IBasketProductRaw, IProductPrice } from 'types/DTO/basket'
import { IProduct, IProductEventGa } from 'types/DTO/products'
import { ICategory } from 'types/DTO/categories'

export default class MindboxService {
  constructor(private $store: any) {}

  mindboxSetCart() {
    const eventItem = this.adaptMboxCartEventItem(this.$store.getters['rph-basket/basketList'])
    this.$store.$repositories.mindbox.sendMindboxEvent('SetCart', eventItem)
  }

  mindboxClearCart() {
    this.$store.$repositories.mindbox.sendMindboxEvent('ClearCart', {})
  }

  mindboxViewCategory(category: ICategory) {
    if(category.id) {
      const eventItem = {
        viewProductCategory: {
          productCategory: {
            ids: {
              website: category.id,
            },
          },
        },
      }
      this.$store.$repositories.mindbox.sendMindboxEvent('ViewCategory', eventItem)
    }


  }

  async mindboxViewProduct(product: IProduct) {
    const priceData = this.$store.$services.productInBasket.getPrice(product)
    const eventItem = {
      viewProduct: {
        product: {
          ids: {
            website: product.id,
          },
        },
        price: priceData.price,
      },
    }
    this.$store.$repositories.mindbox.sendMindboxEvent('ViewProduct', eventItem)
  }

  adaptMboxCartEventItem(products: IBasketProduct[]): any {
    const user = this.$store.$auth?.user
    const productList = products.map((product: IBasketProduct, index: number) => {
      const priceData = this.$store.$services.productInBasket.getPrice(product)
      return {
        product: {
          ids: {
            website: product.id,
          },
        },
        count: product.count,
        pricePerItem: priceData.price,
      }
    })

    if (user) {
      return {
        customer: {
          email: user.email ?? '',
          mobilePhone: user.phone ?? '',
        },
        productList,
      }
    } else {
      return {
        productList,
      }
    }
  }
}
