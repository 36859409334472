import { IHttp, IHttpParams, IHttpResponse } from 'services/common/Http'
import { CommonResponse } from 'types/DTO/common/response'
import { IAddress } from 'types/DTO/addresses'

export interface IAddresses {
  findAll(params: IHttpParams): Promise<any>
}

export default class Addresses implements IAddresses {
  constructor(private http: IHttp) {}

  async findAll(params: IHttpParams): Promise<CommonResponse<IAddress[]>> {
    return await this.http.get('/user-address-api/', params || {})
  }
}
