import { IHttp, IHttpParams, IHttpResponse } from 'services/common/Http'

export interface IBonuses {
  getFaqInfo(params: IHttpParams): Promise<any>
}

export default class Bonuses implements IBonuses {
  constructor(private $http: IHttp) {}

  async getFaqInfo(params: IHttpParams): Promise<any> {
    return await this.$http.get('/api-v2/benefit-faq', params || {})
  }
}