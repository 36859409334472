import { IProductPrice, IProductPriceTypes } from 'types/DTO/products'

export interface IPriceService {
  isExpired(expireDate: string): boolean
  getAvailablePrices(prices: Array<IProductPrice>): Array<string>
  getDefaultPrice(prices: Array<IProductPrice>): string
  getOldPrice(prices: IProductPrice): number | string
}

/**
 * @class PriceService
 * @description A class that is responsible for product prices
 */
export default class PriceService implements IPriceService {
  /**
   * @name isExpired
   * @description checks the expiration date of the product
   */
  isExpired(expireDate: string): boolean {
    // if (expireDate) {
    //   const date = new Date(expireDate)
    //   return !(date >= new Date())
    // }
    return false
  }
  /**
   * @name getAvailablePrices
   * @description returns product`s available prices
   */

  getAvailablePrices(prices: Array<IProductPrice>): Array<string> {
    const availablePrices = []
    for (const key in prices) {
      if (prices[key].price > 0 && prices[key].count > 0 && !this.isExpired(prices[key].expires)) {
        availablePrices.push(key)
      }
    }
    return availablePrices
  }

  /**
   * @name getDefaultPrice
   * @description returns product`s default price
   */

  getDefaultPrice(prices: Array<IProductPrice>): string {
    const availablePrices = this.getAvailablePrices(prices)
    let defaultPrice = ''

    if (availablePrices.includes('standard')) {
      defaultPrice = 'standard'
    } else if (availablePrices.includes('fast_delivery')) {
      defaultPrice = 'fast_delivery'
    } else if (availablePrices.includes('hot_price')) {
      defaultPrice = 'hot_price'
    } else {
      defaultPrice = ''
    }
    return defaultPrice
  }

  /**
   * @name getOldPrice
   * @description returns product`s price without discount
   */
  getOldPrice(prices: IProductPrice): number {
    let oldPrice = 0
    if (prices.price && prices.discount) {
      oldPrice = Math.ceil(((100 * prices.price) / (prices.discount - 100)) * -1)
    }
    return oldPrice ? oldPrice : 0
  }
}
