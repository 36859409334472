import { IHttp, IHttpParams } from 'services/common/Http'

export interface IFaq {
  findAll(params: IHttpParams): Promise<any>
  findAllCategories(params: IHttpParams): Promise<any>
}

export default class Faq implements IFaq {
  constructor(private $http: IHttp) {}

  async findAll(params: IHttpParams): Promise<any> {
    return await this.$http.get(`/faq-api`, params || {})
  }

  async findAllCategories(params: IHttpParams): Promise<any> {
    return await this.$http.get('/faq-category-api/', params || {})
  }
}
