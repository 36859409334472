export interface IBasketCalc {
  price(products: any): number
  count(products: any): number
  discount(products: any): number
  priceDiscount(products: any): number
  total(products: any): number
}

export default class HeaderBasket implements IBasketCalc {
  // @todo удалить лишние методы
  cost(item: any) {
    return item.price * item.count
  }

  costWithoutDiscount(item: any) {
    let oldPrice = item.price
    if (item.discount) {
      oldPrice = Math.ceil(((100 * item.price) / (item.discount - 100)) * -1)
    }
    return Math.trunc(oldPrice) * item.count
  }

  costDiscount(item: any) {
    return (item.price - item.discount) * item.count
  }

  price(products: any) {
    return products.reduce((acc: any, item: any) => {
      return item.disabled ? (acc += 0) : (acc += this.cost(item))
    }, 0)
  }

  total(products: any) {
    return products.reduce((acc: any, item: any) => {
      return item.disabled ? (acc += 0) : (acc += this.costWithoutDiscount(item))
    }, 0)
  }

  priceDiscount(products: any) {
    return products.reduce((acc: any, item: any) => {
      return item.disabled ? (acc += 0) : (acc += this.costDiscount(item))
    }, 0)
  }

  computedDiscount(item: any): number {
    return this.costWithoutDiscount(item) - item.price * item.count
  }

  count(products: any) {
    return products.reduce((acc: any, item: any) => {
      return (acc += item.disabled ? 0 : item.count)
    }, 0)
  }

  discount(products: any) {
    const newProduct = products.map((item: any) => ({
      ...item,
      discount: this.computedDiscount(item),
    }))
    const discount = newProduct.reduce((acc: any, item: any) => {
      return item.disabled ? (acc += 0) : (acc += item.discount)
    }, 0)
    return Math.trunc(discount)
  }
}
