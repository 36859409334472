import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IHttpParams } from 'services/common/Http'
import { IFilterProductForm } from 'types/DTO/filters'

export const state = () => ({
  dosage: [] as [],
  form: [] as IFilterProductForm[],
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setDosage(state, payload) {
    state.dosage = payload
  },
  setForm(state, payload) {
    state.form = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async getDosage({ commit }, params: IHttpParams) {
    try {
      const dosage = await this.$repositories.filters.getDosage(params)
      commit('setDosage', dosage.data)
    } catch (e) {
      console.log('error in getDosage', e)
      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Не получилось загрузить виды дозировки товара',
      })
    }
  },
  async getForm({ commit }, params: IHttpParams) {
    try {
      const forms = await this.$repositories.filters.getForm(params)
      commit('setForm', forms.data)
    } catch (e) {
      console.log('error in getForm', e)
      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Не получилось загрузить формы товара',
      })
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  dosage(state) {
    return state.dosage
  },
  form(state) {
    return state.form
  },
}
