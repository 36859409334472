import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IHttpParams } from 'services/common/Http'
import { IBanner } from 'types/DTO/banners'

export const state = () => ({
  allBanners: [] as IBanner[],
  smallBanners: [] as IBanner[],
  largeBanners: [] as IBanner[],
  hotBanners: {} as IBanner,
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setAllBanners(state, payload) {
    state.allBanners = payload
  },
  setSmallBanners(state, payload) {
    state.smallBanners = payload
  },
  setLargeBanners(state, payload) {
    state.largeBanners = payload
  },
  setHotBanners(state, payload) {
    state.hotBanners = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async getAllBanners({ commit }, params: IHttpParams) {
    try {
      const res = await this.$repositories.banners.findAll(params)
      commit('setAllBanners', res.data)
    } catch (e) {
      console.log('error in getAllBanners', e)
    }
  },
  async getSmallBanners({ commit, state }, params: IHttpParams) {
    try {
      if (state.smallBanners.length) return
      const res = await this.$repositories.banners.findSmallBanners(params)
      commit('setSmallBanners', res.data.data)
    } catch (e) {
      console.log('error in getSmallBanners', e)
    }
  },
  async getLargeBanners({ commit, state }, params: IHttpParams) {
    try {
      if (state.largeBanners.length) return
      const res = await this.$repositories.banners.findLargeBanners(params)
      commit('setLargeBanners', res.data.data)
    } catch (e) {
      console.log('error in getLargeBanners', e)
    }
  },

  async getHotBanners({ commit }, params: IHttpParams) {
    try {
      const res = await this.$repositories.banners.findHotBanners(params)
      const data = this.$services.banners.adapt(res.data.data)

      commit('setHotBanners', data)
    } catch (e) {
      console.log('error in getHotBanners', e)
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  allBanners(state) {
    return state.allBanners
  },
  smallBanners(state) {
    return state.smallBanners
  },
  largeBanners(state) {
    return state.largeBanners
  },
  hotBanners(state) {
    return state.hotBanners
  },
}
