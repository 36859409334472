export default {
  SET_REMEMBER_TOKEN(state, token) {
    state.token = token
  },
  SET_REMEMBER_PHONE(state, phone) {
    state.phone = phone
  },
  CLEAR_REMEMBER_DATA(state) {
    state.token = ''
    state.phone = ''
  },
}
