export interface IFieldValidator {
  nameRegex: RegExp
  houseRegex: RegExp
  cardNameRegex: RegExp
  emailRegex: RegExp
  textRegex: RegExp

  isName(name: string): boolean

  isEmail(email: string): boolean

  isPassword(password: string): boolean

  isText(text: string): boolean

  isHouse(text: string): boolean

  isPhoneNumberFull(phone: string): boolean

  isCardNumberFull(cardNumber: string): boolean

  isExpiryDateValid(expiryDate: string): boolean

  isLatinChars(name: string): boolean
}

/**
 * @class FieldValidator
 * @description A class that has methods of fields validations
 */
export default class FieldValidator implements IFieldValidator {
  nameRegex: RegExp = /^[a-zA-Zа-яА-ЯӘәІіҢңҒғҮүҰұҚқӨөҺһ\/– ]+$/

  houseRegex: RegExp =
    /^(?![A-Za-zА-Яа-я])(?=[^A-Za-zА-Яа-я]*[0-9])(?:[^A-Za-zА-Яа-я]*[A-Za-zА-Яа-я]){0,2}[^A-Za-zА-Яа-я]*$/

  cardNameRegex: RegExp = /^[a-zA-Z ]+$/

  textRegex: RegExp = /^\d+$/

  latinChars: RegExp = /^[a-zA-Z\s\-_]+$/

  emailRegex: RegExp =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})/

  /**
   * @name isName
   * @description validates 'name' fields. Accepts only latin/cyrillic characters and '-'/'–' symbols
   * @param {string} name
   * @return {boolean}
   */
  isName(name: string): boolean {
    return this.nameRegex.test(name)
  }

  isHouse(house: string): boolean {
    return this.houseRegex.test(house)
  }

  /**
   * @name isEmail
   * @description validates 'email' fields. Accepts only email pattern
   * @param {string} email
   * @return {boolean}
   */
  isEmail(email: string): boolean {
    return this.emailRegex.test(email)
  }

  /**
   * @name isPassword
   * @description validates 'password' fields
   * @param {string} password
   */
  isPassword(password: string): boolean {
    return /^[^\sА-Яа-яЁё]+$/.test(
      password,
    )
  }

  /**
   * @name isText
   * @description validates 'text' fields. Accepts digits
   * @param {string} text
   */
  isText(text: string): boolean {
    return this.textRegex.test(text)
  }

  /**
   * @name isPhoneNumberFull
   * @description validates 'text' fields. Accepts only a string of numbers
   * @param {string} phone
   */
  isPhoneNumberFull(phone: string): boolean {
    return phone.replace(/\s/g, '').length < 12
  }

  /**
   * @name isCardNumberFull
   * @description validates 'cart number' fields. Accepts only a string of numbers
   * @param {string} number
   */
  isCardNumberFull(number: string): boolean {
    return number.replace(/\s/g, '').length < 16
  }

  /**
   * @name isExpiryDateValid
   * @description validates 'card expiry date'. Accepts string
   * @param {string} expiryDate
   */
  isExpiryDateValid(expiryDate: string): boolean {
    const expDate = expiryDate.split('/')
    const month = parseInt(expDate[0])
    return expiryDate.length === 5 && month > 0 && month < 13
  }

  /**
   * @name isLatinChars
   * @description validates 'cart name' fields. Accepts only a string of strings
   * @param {string} name
   */
  isLatinChars(name: string): boolean {
    return this.latinChars.test(name)
  }
}
