import { IContact, IContactDetails } from '~/types/DTO/contacts'

type IContactData = {
  id: number
  type: string
  attributes: IContact
}

export default class ContactsService {
  mapContacts(data: IContactData[]): IContact[] {
    return data.map((el) => el.attributes)
  }

  mapAddressDetails(data: any): IContactDetails[] {
    return [
      // {
      //   id: 1,
      //   title: 'Адрес',
      //   description: '<p>ул. Сатпаева 72а,</p> угол ул. Розыбакиева' || data.address,
      // },
      {
        id: 2,
        title: 'Режим работы',
        description: '10.00-19.00 без выходных' || `Ежедневно ${data.time}`,
      },
      {
        id: 3,
        title: 'Телефон',
        description: '+7 771 558 01 13' || data.phone,
      },
      {
        id: 4,
        title: 'Эл. почта',
        description: 'hello@aurma.kz' || data.email,
      },
    ]
  }
}
