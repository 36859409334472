import { IHttp, IHttpParams, IHttpResponse } from 'services/common/Http'
const apiKey = process.env.YANDEX_KEY

export interface IGeocoder {
  getAddressByCoords(coords: string, params?: IHttpParams): Promise<any>
}

export default class Geocoder implements IGeocoder {
  constructor(private http: IHttp) {}

  async getAddressByCoords(coords: string, params: IHttpParams): Promise<any> {
    return await this.http.get(
      `https://geocode-maps.yandex.ru/1.x/?apikey=${apiKey}&format=json&kind=house&geocode=${coords}&results=1`,
      params
    )
  }
}
