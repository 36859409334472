import { IUser } from 'types/DTO/users'

export interface IUsers {
  adaptUser(user: IUser): IUser
}

export default class Users implements IUsers {
  adaptUser(user: IUser): IUser {
    return {
      id: user.id,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone,
      avatar:
        user.avatar && !user.avatar.includes('null') ? process.env.BASE_URL + user.avatar : '',
      player_id: user.player_id,
      auth_key: user.auth_key,
    }
  }
}
