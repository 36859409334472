import { CommonResponse } from 'types/DTO/common/response'
import { IHttpParams, IHttp } from 'services/common/Http/index'

export interface IConfig {
  findAll(params: IHttpParams): Promise<CommonResponse<any>>
}

export default class Config implements IConfig {
  constructor(private $http: IHttp) {}

  async findAll(params: IHttpParams): Promise<CommonResponse<any>> {
    return await this.$http.get('/settings-api/', {
      params,
    })
  }
}
