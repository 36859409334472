export default class Pagination {
  constructor() {}

  public getPages(
    currentPage: number,
    totalPages: number,
    offSet: number,
    pageToShow: number
  ): number[] {
    const maxPageToShow = pageToShow
    const offset = offSet

    const pages = Array(totalPages)
      .fill(0)
      .map((_, i) => i + 1)
    if (totalPages <= maxPageToShow) {
      return pages
    } else {
      let start = 0
      let end = start + maxPageToShow

      if (maxPageToShow <= currentPage + 1) {
        start = Math.max(currentPage - offset, 1)
        end = Math.min(currentPage + offset, totalPages)

        if (offset <= 1) {
          start = Math.min(currentPage + 1 - maxPageToShow, totalPages - maxPageToShow)
          end = start + maxPageToShow
        }
      }

      const arr = pages.filter((page) => {
        return start < page && end >= page
      })

      return [...arr]
    }
  }
}
