import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IHttpParams } from 'services/common/Http'
import { IFooterMenu } from 'types/DTO/footer'
import { ISocial, ISocialsRawAttributes } from '~/types/DTO/socials'

export const state = () => ({
  socials: [] as ISocial[],
  copyright: '' as string,
  workHours: '' as string,
  settings: [] as [],
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setSocials(state, payload) {
    state.socials = payload
  },
  setCopyright(state, payload) {
    state.copyright = payload
  },
  setWorkHours(state, payload) {
    state.workHours = payload
  },
  setSettings(state, payload) {
    state.settings = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async getSettings({ commit, state }, params: IHttpParams) {
    try {
      const { data } = await this.$repositories.footer.findSettings(params)
      const settings = this.$services.footer.mapSettings(
        data.data,
        this.$services.footer.footerSettingsParams
      )
      const copyright = this.$services.footer.mapText(settings, 'copyright')
      const workHours = this.$services.footer.mapText(settings, 'footer_work_hours')
      commit('setSettings', data.data)
      commit('setSocials', this.$services.footer.mapSocials(data.data))
      commit('setCopyright', copyright)
      commit('setWorkHours', workHours)
    } catch (e) {
      console.log('error in getSocials', e)
      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Не получилось загрузить settings',
      })
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  socials(state) {
    return state.socials
  },
  copyright(state) {
    return state.copyright
  },
  workHours(state) {
    return state.workHours
  },
  settings(state) {
    return state.settings
  },
}
