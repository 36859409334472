export interface IIp {
  fetchIp(): Promise<string>
}

/**
 * @class Ip
 * @description A class that has methods of ip fetch
 */
export default class Ip implements IIp {
  /**
   * @name fetchIp
   * @description returns ip
   */
  async fetchIp(): Promise<string> {
    const response = await fetch('https://api.ipify.org?format=json')
    const { ip } = await response.json()
    return ip
  }
}
