import { IHttp, IHttpParams } from 'services/common/Http'
import { CommonResponse } from 'types/DTO/common/response'
import { IUser, IUserData, IUserEdit } from 'types/DTO/users'
import { IUserAddress } from 'types/DTO/profile'

export interface IUsers {
  findUser(id: number, params: IHttpParams): Promise<CommonResponse<IUser>>
  signUp(data: IUserData, params: IHttpParams): Promise<CommonResponse<IUser>>
  logout(id: number, params: IHttpParams): Promise<any>
  preRegistration(data: any, params: IHttpParams): Promise<any>
  checkUser(data: any, params: IHttpParams): Promise<any>
  editUser(id: number, data: IUserEdit): Promise<any>
  changeAvatar(id: number, avatar: File): Promise<any>
  updateAddress(id: number, data: IUserAddress): Promise<Object>
}

export default class Users implements IUsers {
  constructor(private http: IHttp) {}

  async findUser(id: number, params: IHttpParams): Promise<CommonResponse<IUser>> {
    return await this.http.get(`/api-v2/user/${id}`, params || {})
  }

  async editUser(id: number, data: IUserEdit): Promise<any> {
    return await this.http.put(`/api-v2/user/${id}`, new URLSearchParams({ ...data }))
  }

  async changeAvatar(id: number, avatar: File): Promise<any> {
    const fd = new FormData()
    fd.append('avatar', avatar)
    fd.append('id', String(id))

    return await this.http.post(`/api-v2/user/avatar/${id}`, fd)
  }

  async signUp(data: IUserData, params: IHttpParams): Promise<CommonResponse<IUser>> {
    const fd = new FormData()
    for (const field in data) {
      // @ts-ignore
      fd.append(`${field}`, data[field])
    }

    return await this.http.post('/api-v2/user', fd, params)
  }

  async logout(id: number, params: IHttpParams): Promise<any> {
    return await this.http.post(`/api-v2/user/logout/${id}`, params || {})
  }

  async preRegistration(data: any, params: IHttpParams): Promise<CommonResponse<IUser>> {
    const fd = new FormData()
    for (const field in data) {
      // @ts-ignore
      fd.append(`${field}`, data[field])
    }
    return await this.http.post(`/api-v2/user/pre-registration`, fd, params || {})
  }

  async checkUser(params: IHttpParams): Promise<CommonResponse<IUser>> {
    return await this.http.get(`/api-v2/user/check-user`, params)
  }

  async updateAddress(id: number, data: IUserAddress): Promise<Object> {
    const formData = new FormData()

    for (const field in data) {
      // @ts-ignore
      formData.append(`${field}`, data[String(field) as keyof IUserAddress])
    }
    // @ts-ignore
    return await this.http.put(`/api-v2/user-address/${id}`, formData)
  }
}
