import { IHttp, IHttpParams } from 'services/common/Http'
import { IOrder } from 'types/DTO/order'
import { IBasketProduct } from 'types/DTO/basket'

export interface IOrders {
  addOrder(data: IOrder, params: IHttpParams): Promise<any>
  orderInit(data: any, params: IHttpParams): Promise<any>
  post3ds(data: any, params: IHttpParams): Promise<any>
  findOrderPaymentMethods(params: IHttpParams): Promise<any>
  findOrderById(id: number, params: IHttpParams): Promise<any>
  findDeliveryArea(params: IHttpParams): Promise<any>
  getDeliveryPrice(params: IHttpParams): Promise<any>
  updateOrderStatus(id: number, params: IHttpParams): Promise<any>
}

export default class Orders implements IOrders {
  constructor(private http: IHttp) {}

  async findOrderById(id: number, params: IHttpParams): Promise<any> {
    return await this.http.get(`/order-api/?id=${id}`, params)
  }

  async updateOrderStatus(id: number, params: IHttpParams): Promise<any> {
    return await this.http.post(
      `/api-v2/orders/status-update/${id}`,
      this.http.toFormData(params),
    )
  }

  async post3ds(data: any, params: IHttpParams): Promise<any> {
    const formData = new FormData()
    for (const key in data) {
      formData.append(key, String(data[key as string]))
    }
    return await this.http.post(
      `/api-v2/orders/post3ds`,
      formData, params)
  }

  async orderInit(data: any, params: IHttpParams): Promise<any> {
    const fd = new FormData()
    // @ts-ignore
    for (const field in data) {
      if (field === 'productsInitArr') {
        // @ts-ignore
        data[field].forEach((item, index) => {
          fd.append(`Order[products_id][${index}][id]`, item.id)
          fd.append(`Order[products_id][${index}][count]`, item.count)
          fd.append(`Order[products_id][${index}][is_exp]`, item.is_exp)
          fd.append(`Order[products_id][${index}][pharmacy_id]`, item.pharmacy_id)
        })
      } else {
        // @ts-ignore
        fd.append(`Order[${field}]`, data[field])
      }
    }
    return await this.http.post('/order-api/init', fd, params)
  }

  async addOrder(data: IOrder, params: IHttpParams): Promise<any> {
    const fd = new FormData()
    for (const field in data) {
      // @ts-ignore
      if (field === 'products_id') {
        // @ts-ignore
        data[field].forEach((item, index) => {
          fd.append(`Order[products_id][${index}][id]`, item.id)
          fd.append(`Order[products_id][${index}][count]`, item.count)
          fd.append(`Order[products_id][${index}][is_exp]`, item.is_hot)
          // @ts-ignore
          fd.append(`Order[products_id][${index}][is_competitor]`, null)
          fd.append(`Order[products_id][${index}][pharmacy_id]`, item.pharmacy_id)
          fd.append(`Order[products_id][${index}][provider_id]`, item.provider_id)
        })
      } else if (field === 'promocode') {
        const promocode = data[field] as any
        fd.append(
          'Order[promocode]',
          typeof promocode === 'object' && promocode.code.length ? promocode.code : '',
        )
      } else {
        // @ts-ignore
        fd.append(`Order[${field}]`, data[field])
      }
    }
    // return await this.$axios.$post('/order-api/register/', formData)
    return await this.http.post('/order-api/register/', fd, params)
  }

  async findOrderPaymentMethods(params: IHttpParams): Promise<any> {
    return await this.http.get('/payment-method-api', params || {})
  }

  async findDeliveryArea(params: IHttpParams): Promise<any> {
    return await this.http.get('api-v2/delivery', params)
  }
  async getDeliveryPrice(params: IHttpParams): Promise<any> {
    return await this.http.get('api-v2/delivery/get-price-yandex-delivery', params)
  }
}
