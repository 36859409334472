export default {
  SET_NEWS(state, { data, links, meta }) {
    state.news = data

    for (const link in links) {
      state.news_info.links[link] = links[link].href
    }

    for (const m in meta) {
      state.news_info.meta[m] = meta[m]
    }
  },
  SET_PAGINATION_PAGE(state, payload) {
    state.news_info.meta['current-page'] = payload
  },
}
