import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IAddress } from 'types/DTO/addresses/index'
import { IHttpParams } from 'services/common/Http'

export const state = () => ({
  addresses: [] as IAddress[],
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setAddresses(state, payload) {
    state.addresses = payload.data
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async getAddresses({ commit }, params: IHttpParams) {
    try {
      const res = await this.$repositories.addresses.findAll(params)
      commit('setAddresses', res.data)
      console.log('addresses fetched')
      // @ts-ignore
    } catch {
      console.log('error: getAddresses')
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  addresses(state) {
    return state.addresses
  },
}
