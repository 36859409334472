export interface ICardsService {
  createMask(card: any): string
}

export default class Cards {
  createMask(card: any): string {
    const expiryDate = card.expiration.split('/')
    const month = parseInt(expiryDate[0], 10)
    const year = parseInt(expiryDate[1], 10)
    return '****' + ' ' + card.cardNumber.substr(-4) + ' ' + month + '/' + year
  }
}
