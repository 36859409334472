import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IProfileAddress, IUserOrder, IProfileAttribute, ICities } from 'types/DTO/profile'
import { IMetaData } from 'types/DTO/common/response'
import { IHttpParams } from 'services/common/Http'

export const state = () => ({
  addresses: [] as IProfileAddress[],
  orders: [] as IUserOrder[],
  meta: {} as IMetaData,
  /* Открыть/Закрыть форму редактирования/добавления адреса */
  address_form: false as boolean,
  is_edit: false as boolean,
  card_form: false as boolean,
  edit_address: {} as IProfileAttribute,
  /* Открыто/Закрыто редактирование профиля */
  is_edit_profile: false as boolean,
  city: [] as ICities[],
  suggestAdresses: [] as any[],
  isAddressPresent: true,
  isAgreedToMailings: null
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setAddresses(state, payload) {
    state.addresses = payload
  },
  setOrders(state, payload) {
    state.orders = payload
  },
  setMetaOrder(state, payload) {
    state.meta = payload
  },
  setAddressForm(state, payload) {
    state.address_form = payload
  },
  setIsEdit(state, payload) {
    state.is_edit = payload
  },
  setCardForm(state, payload) {
    state.card_form = payload
  },
  setEditAddress(state, payload) {
    state.edit_address = payload
  },
  setIsEditProfile(state, payload) {
    state.is_edit_profile = payload
  },
  setCity(state, payload) {
    state.city = payload
  },

  setSuggestAddresses(state, payload) {
    state.suggestAdresses = payload
  },

  closeForms(state) {
    state.address_form = false
    state.card_form = false
    state.is_edit_profile = false
  },
  setAddressPresent(state, payload) {
    state.isAddressPresent = payload
  },

  setIsAgreedToMailings(state, payload) {
    state.isAgreedToMailings = payload
  }
}

export const actions: ActionTree<stateT, stateT> = {
  async getAddresses({ commit }, params: IHttpParams) {
    try {
      const { data } = await this.$repositories.profile.FindAllAddresses(params)
      commit('setAddresses', data.data)
    } catch {
      console.log('error: getAddresses')
    }
  },

  async getUserOrders({ commit }, params: IHttpParams) {
    try {
      const { data } = await this.$repositories.profile.getOrders(params)
      commit('setOrders', data.data)
      commit('setMetaOrder', data.meta)
    } catch (e) {
      console.log(e)
    }
  },

  async getUserOrdersByTitle({ commit }, params: IHttpParams) {
    try {
      const { data } = await this.$repositories.profile.getOrdersByTitle(params)
      commit('setOrders', data.data)
      commit('setMetaOrder', data.meta)
    } catch (e) {
      console.log(e)
    }
  },

  async removeAddress({ commit }, { id, params }) {
    try {
      await this.$repositories.profile.deleteUserAddress(id, params)

      this.$services.toaster.add({
        type: 'success',
        title: '',
        description: 'Адреса доставки успешно удален',
      })
    } catch (e) {
      console.log(e)

      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Произошла ошибка удаления адреса доставки',
      })
    }
  },

  async addUserAddress({ commit }, data) {
    try {
      await this.$repositories.profile.addUserAddress(data)

      this.$services.toaster.add({
        type: 'success',
        title: '',
        description: 'Адрес успешно добавлен',
      })
    } catch (e) {
      console.log(e)

      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Ошибка добавления адреса',
      })
    }
  },

  async setDefaultAddress({ commit, state }, { id, data, defaultEvent }) {
    try {
      await this.$repositories.profile.updateUserAddress(id, data)
      this.$services.toaster.add({
        type: 'success',
        title: '',
        description: defaultEvent ? 'Выбран адрес по умолчанию' : 'Адрес успешно обновлён',
      })
    } catch (e) {
      console.log(e)
      if (state.address_form) {
        this.$services.toaster.add({
          type: 'error',
          title: '',
          description: 'Ошибка обновления',
        })
      } else {
        this.$services.toaster.add({
          type: 'error',
          title: '',
          description: 'Ошибка при выборе адреса',
        })
      }
    }
  },

  async getCities({ commit }, params: IHttpParams) {
    try {
      const { data } = await this.$repositories.profile.findAllCities(params)
      commit('setCity', data.data)
    } catch (e) {
      console.log(e)
    }
  },

  async getSuggestAddresses({ commit }, params: IHttpParams) {
    try {
      const res: any = await this.$repositories.profile.getAddressSuggest(params)
      commit('setSuggestAddresses', res.data?.results ?? [])
      return res.data
    } catch (e) {
      commit('setSuggestAddresses', [])
      console.log(e)
    }
  },

  async getMindboxUserInfo({commit}, {id, params}) {
    try {
      const res = await this.$repositories.profile.getUserMindboxInfo(id, params)
      commit('setIsAgreedToMailings', res.data.consent_to_mailings)
    } catch (e) {
      console.log(e)
    }
  },

  async setUserMailingsAgreement({commit}, {id, agreement, params}) {
    try {
      const res = await this.$repositories.profile.setUserMailingsAgreement(id, agreement, params)
      if (res) {
        commit('setIsAgreedToMailings', agreement)
      }
    } catch (e) {
      console.log(e)
    }
  }
}

export const getters: GetterTree<stateT, stateT> = {
  addresses(state) {
    return state.addresses
  },
  orders(state) {
    return state.orders
  },
  meta(state) {
    return state.meta
  },
  openForm(state) {
    return state.address_form
  },
  isEdit(state) {
    return state.is_edit
  },
  openCard(state) {
    return state.card_form
  },
  editAddress(state) {
    return state.edit_address
  },
  isEditProfile(state) {
    return state.is_edit_profile
  },
  city(state) {
    return state.city
  },
  suggestAddresses(state) {
    return state.suggestAdresses
  },
  getAddressIsPresent(state) {
    return state.isAddressPresent
  },
  isAgreedToMailings(state) {
    return state.isAgreedToMailings
  }
}
