import { IFooterMenu, IFooterRaw } from 'types/DTO/footer'
import { ISocial, ISettings, ISocialsRawAttributes } from '~/types/DTO/socials'

export default class FooterService {
  socialParams = ['instagram_link']

  footerSettingsParams = [
    'facebook_link',
    'vk_link',
    'instagram_link',
    'copyright',
    'footer_work_hours',
  ]

  /**
   * It takes an array of objects, filters them by a property, and then maps the filtered array to a
   * new array of objects
   * @param {IFooterRaw[]} data - IFooterRaw[] - this is the data that we get from the API.
   * @param [position=2] - The position of the menu in the footer.
   * @returns An array of objects with the following properties: id, title, url
   */
  mapMenu(data: IFooterRaw[], position = 2): IFooterMenu[] {
    return data
      .filter((item) => item.attributes.position === position)
      .map(
        (item): IFooterMenu => ({
          id: item.attributes.id,
          title: item.attributes.title,
          url: `/${item.attributes.url}`,
        }),
      )
  }

  /**
   * It takes an array of objects with a specific structure, and returns an array of objects with a
   * different structure
   * @param {ISocialsRawAttributes[]} data - ISettings[] - the data that we get from the API
   * @returns An array of objects with the following properties:
   *   id: number
   *   type: string
   *   url: string
   */
  mapSocials(data: ISettings[]): ISocial[] {
    const social = this.mapSettings(data, this.socialParams) as ISocialsRawAttributes[]
    return social.map((item) => ({
      id: item.id,
      type: item.param.replace('_link', ''),
      url: item.value,
    }))
  }

  mapText(data: ISocialsRawAttributes[], type: string): string {
    return data.find((item) => item.param === type)?.value || ''
  }

  mapSettings(data: ISettings[], array: string[]): ISocialsRawAttributes[] {
    const settings: ISocialsRawAttributes[] = []
    array.forEach((item) => {
      const setting = data.find((setting) => setting.attributes.param === item)
      if (setting) settings.push(setting.attributes)
    })
    return settings
  }
}
