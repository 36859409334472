import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IMenuObject } from 'types/DTO/menu/index'
import { IHttpParams } from 'services/common/Http'

export const state = () => ({
  menu: [] as IMenuObject[],
  footer: [] as IMenuObject[],
  menu_about: {
    info: [] as IMenuObject[],
    buyer: [] as IMenuObject[],
    docs: [] as IMenuObject[],
    partner: [] as IMenuObject[],
  },
  isHeaderShown: true
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setMenu(state, payload) {
    state.menu = payload
  },
  setFooter(state, payload) {
    state.footer = payload
  },
  setMenuAbout(state, payload) {
    const { info, buyer, docs, partner } = payload
    state.menu_about.info = info
    state.menu_about.buyer = buyer
    state.menu_about.docs = docs
    state.menu_about.partner = partner
  },
  setIsHeaderShown(state, payload) {
    state.isHeaderShown = payload
  }
}

export const actions: ActionTree<stateT, stateT> = {
  async getMenu({ commit }, params: IHttpParams) {
    try {
      const { data } = await this.$repositories.menu.findAll(params)
      commit('setMenu', data.data.header)
      commit('setFooter', data.data.footer)

      const { info, buyer, docs, partner } = data.data
      const res = await this.$services.faq.setAboutIcons({ info, buyer, docs, partner })
      commit('setMenuAbout', res)
    } catch (e) {
      console.log('error in getMenu', e)
      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Не получилось загрузить меню',
      })
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  menu(state) {
    return state.menu
  },
  footer(state) {
    return state.footer
  },
  menuAbout(state) {
    return state.menu_about
  },
  isHeaderShown(state) {
    return state.isHeaderShown;
  }
}
