import { IMenuObject } from 'types/DTO/menu/index'
import { CommonResponse } from 'types/DTO/common/response'
import { IHttp, IHttpParams, IHttpResponse } from 'services/common/Http'

export interface IMenu {
  findAll(params: IHttpParams): Promise<IHttpResponse<CommonResponse<IMenuObject>>>
}

export default class Menu implements IMenu {
  constructor(private $http: IHttp) {}

  async findAll(
    params: IHttpParams
  ): Promise<IHttpResponse<CommonResponse<IMenuObject>>> {
    return await this.$http.get('/api-v2/menu', { params })
  }
}
