import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IContent } from 'types/DTO/content'

export const state = () => ({
  content: {} as IContent,
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setContent(state, payload) {
    state.content = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async getContents({ commit }, { slug, id, params }) {
    try {
      const res = await this.$repositories.content.findContent(slug, id, params)

      commit('setContent', res.data.data[0])
    } catch (e) {
      console.log('error in getContents', e)
      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Не получилось загрузить Контент',
      })
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  content(state) {
    return state.content
  },
}
