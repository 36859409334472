export default class HeadCreator {
  constructor(env, route) {
    this.$env = env
    this.$route = route
  }

  createMeta({ title, description, keywords, pageType, ogImage }) {
    return [
      {
        hid: 'title',
        name: 'title',
        content: title,
      },
      {
        hid: 'description',
        name: 'description',
        content: description,
      },
      {
        hid: 'keywords',
        name: 'keywords',
        content: keywords || '',
      },
      {
        hid: 'robots',
        name: 'robots',
        content: 'index, follow',
      },
      {
        hid: 'HandheldFriendly',
        name: 'HandheldFriendly',
        content: 'True',
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: title,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: description,
      },
      {
        hid: 'og:site_name',
        property: 'og:site_name',
        content: this.$env.BASE_DOMAIN_URL,
      },
      {
        hid: 'og:image',
        name: 'og:image',
        content: ogImage || `${this.$env.BASE_DOMAIN_URL}/og-main.jpg`,
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: this.$env.BASE_DOMAIN_URL + this.$route.path,
      },
      {
        hid: 'og:type',
        property: 'og:type',
        content: pageType || 'website',
      },
      {
        hid: 'og:locale',
        property: 'og:locale',
        content: 'ru-RU',
      },
      {
        hid: 'twitter:title',
        property: 'twitter:title',
        content: title,
      },
      {
        hid: 'twitter:description',
        property: 'twitter:description',
        content: description,
      },
      {
        hid: 'twitter:url',
        property: 'twitter:url',
        content: this.$env.BASE_DOMAIN_URL + this.$route.path,
      },
      {
        hid: 'twitter:card',
        property: 'twitter:card',
        content: 'summary_large_image',
      },
    ]
  }

  createCanonicalLink(routePath = '') {
    return {
      rel: 'canonical',
      href: this.$env.BASE_DOMAIN_URL + routePath,
    }
  }
}
