import { IHttp, IHttpParams } from 'services/common/Http'

export interface IDisease {
  getDiseaseCategories(params: IHttpParams): Promise<any>
  getDiseaseList(params: IHttpParams): Promise<any>
  findBySlug(slug: string, params: IHttpParams): Promise<any>
}

export default class Disease implements IDisease {
  constructor(private http: IHttp) {}

  async getDiseaseCategories(params: IHttpParams): Promise<any> {
    return await this.http.get(`/api-v2/disease/categories`, params)
  }

  async getDiseaseList(params: IHttpParams): Promise<any> {
    return await this.http.get(`/api-v2/disease`, params)
  }

  async findBySlug(slug: string, params: IHttpParams): Promise<any> {
    return await this.http.get(`/api-v2/disease/${slug}`, params || {})
  }
}
