import { IBreadCrumbs } from 'types/DTO/breadCrumbs'

export default class Breadcrumbs {
  constructor() {}

  mappingItem(item: IBreadCrumbs) {
    return {
      title: item.title,
      slug: item.slug,
    }
  }

  createCrumbs(arr: IBreadCrumbs[]) {
    return [{ title: 'Главная', slug: '' }, ...arr.map((item) => this.mappingItem(item))]
  }
}
