import { IBasketProduct } from 'types/DTO/basket'

export default class AnyQueryService {
  constructor(private $store: any) {}

  async anyQueryViewProduct(product: any) {
    const eventItem = {
      productId: product.id,
      productName: product.title,
      availability: product.prices?.standard.count ?? 0 !== 0 ? 1 : 0,
    }
    this.$store.$repositories.anyQuery.sendAnyQueryEvent('PRODUCT_VIEW', eventItem)
  }

  anyQuerySetCart() {
    const eventItem = this.adaptAnyQueryCartEventItem(this.$store.getters['rph-basket/basketList'])
    this.$store.$repositories.anyQuery.sendAnyQueryEvent('CART_VIEW', eventItem)
  }

  anyQueryOrderSuccess() {
    const eventItem = this.adaptAnyQueryCartEventItem(this.$store.getters['rph-basket/basketList'])

    this.$store.$repositories.anyQuery.sendAnyQueryEvent('ORDER_SUCCESS', eventItem)
  }

  anyQuerySearchEvent(searchData: any) {
    this.$store.$repositories.anyQuery.sendAnyQueryEvent('SEARCH_EVENT', searchData)
  }

  adaptAnyQueryCartEventItem(products: IBasketProduct[]): any {
    const orderId = this.$store.$router?.query?.id
    const productsPrice = this.$store.getters['rph-basket/productsPrice']
    const price = this.$store.$services.basket.calc.total(productsPrice)
    const productList = products.map((product: IBasketProduct, index: number) => {
      const priceData = this.$store.$services.productInBasket.getPrice(product)
      return {
        productId: product.id,
        productName: product.title,
        sku: null,
        price: priceData.price,
        quantity: product.count,
        salePrice: priceData.price - priceData.discount,
      }
    })

    return {
      ...(orderId && { orderId }),
      subtotal: price,
      total: this.$store.getters['rph-basket/totalPrice'],
      cartId: localStorage.getItem('basketId'),
      cartItems: productList,
    }
  }
}
