import { Context } from '@nuxt/types'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

export interface IHttpParams extends AxiosRequestConfig {}

export interface IHttpResponse<T> extends AxiosResponse<T> {
  data: T
}

export interface IHttp {
  toFormData<T>(data: T): FormData

  get<T>(url: string, params: IHttpParams): Promise<IHttpResponse<T>>

  post<D, T>(url: string, data: D, params?: IHttpParams): Promise<IHttpResponse<T>>

  delete<T>(url: string, params?: IHttpParams): Promise<IHttpResponse<T>>

  put<D, T>(url: string, data?: D, params?: IHttpParams): Promise<IHttpResponse<T>>
}

export default class Http implements IHttp {
  constructor(private client: Context['$axios']) {}

  toFormData<T>(data: T): FormData {
    const formData = new FormData()

    for (const i in data) {
      formData.append(i, JSON.stringify(data[i]))
    }

    return formData
  }

  async get<T>(url: string, params: IHttpParams): Promise<IHttpResponse<T>> {
    return await this.client.get<T>(url, params)
  }

  async post<D, T>(
    url: string,
    data: D,
    params?: IHttpParams
  ): Promise<IHttpResponse<T>> {
    return await this.client.post<T>(url, data, params)
  }

  async delete<T>(url: string, params?: IHttpParams): Promise<IHttpResponse<T>> {
    return await this.client.delete(url, params)
  }

  async put<D, T>(url: string, data: D, params?: IHttpParams): Promise<IHttpResponse<T>> {
    return await this.client.put(url, data, params)
  }
}
