import { CommonResponse } from 'types/DTO/common/response'
import { IHttp, IHttpParams, IHttpResponse } from 'services/common/Http'
import { ICategory } from '~/types/DTO/categories'

export interface ICategories {
  findAll(params: IHttpParams): Promise<IHttpResponse<CommonResponse<ICategory[]>>>
  findBySlug(
    slug: string,
    params: IHttpParams
  ): Promise<IHttpResponse<CommonResponse<ICategory>>>
}

export default class Categories implements ICategories {
  constructor(private $http: IHttp) {}

  async findAll(
    params: IHttpParams
  ): Promise<IHttpResponse<CommonResponse<ICategory[]>>> {
    return await this.$http.get('/api-v2/categories', { params })
  }

  async findBySlug(
    slug: string,
    params: IHttpParams
  ): Promise<IHttpResponse<CommonResponse<ICategory>>> {
    return await this.$http.get(`/api-v2/categories/${slug}`, params)
  }
}
