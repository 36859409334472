import { Context } from '@nuxt/types'
import { IToast } from 'types/DTO/toast'

export interface IToaster {
  add(data: IToast): void
  delete(index: number): void
}

export default class Toaster implements IToaster {
  constructor(private $store: Context['store']) {}

  add(data: IToast): void {
    this.$store.commit('rph-toast/addNotification', data)
  }

  delete(index: number): void {
    this.$store.commit('rph-toast/deleteNotification', index)
  }
}
