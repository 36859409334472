import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { ICard, ICardData } from 'types/DTO/cards'
import { IHttpParams } from 'services/common/Http'

export const state = () => ({
  cards: [] as ICard[],
  newCard: {},
  isSending: false,
  packet: '',
  cardNumberInvalid: '',
  /* Ошибка при добавлении карты */
  success: false,
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setCards(state, payload) {
    state.cards = payload.data
  },
  setCard(state, payload) {
    state.cards.push(payload.data.model)
  },
  setNewCard(state, payload) {
    state.cards.push(payload.data.model)
    state.newCard = payload.data.model
  },
  clearNewCard(state) {
    state.newCard = {}
  },
  deleteCard(state, id) {
    const index = state.cards.findIndex((card) => card.id === id)
    state.cards.splice(index, 1)
  },
  setIsSending(state, payload) {
    state.isSending = payload
  },
  setSuccess(state, payload) {
    state.success = payload
  },
  setCardNumberInvalid(state, payload) {
    state.cardNumberInvalid = payload
  },
  setPacket(state, payload) {
    state.packet = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async getCards({ commit }, params: IHttpParams) {
    try {
      const res = await this.$repositories.cards.findAll(params)
      commit('setCards', [])
      commit('setCards', res.data)
      // @ts-ignore
    } catch {
      console.log('error: getCards')
    }
  },
  async addCard({ commit }, data: ICardData) {
    try {
      commit('setIsSending', true)
      // @ts-ignore
      const res = await this.$repositories.cards.addCard(data, '')
      commit('setNewCard', res.data)

      this.$services.toaster.add({
        type: 'success',
        title: '',
        description: 'Карта добавлена успешно',
      })
      // @ts-ignore
    } catch (e) {
      console.log(e)
      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Ошибка при добавлении карты',
      })
    } finally {
      commit('setIsSending', false)
    }
  },
  async deleteCard({ commit }, id: number) {
    try {
      await this.$repositories.cards.deleteCard(id).then(() => {
        commit('deleteCard', id)
      })

      this.$services.toaster.add({
        type: 'success',
        title: '',
        description: 'Карта удаленна успешно',
      })
    } catch {
      console.log('error: deleteCard')

      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Ошибка удалении карты',
      })
    }
  },
  // :TODO переделать когда будут ресурсы бэка
  // анонимные запросы на добавление и удаление карт для новых карт, которые юзер не хочет запоминать
  // дублируемые фунции, чтобы не менять оригинальные по всему проекту
  async deleteAnonCard({ commit }, id: number) {
    try {
      if (id !== null) {
        await this.$repositories.cards.deleteCard(id).then(() => {
          commit('deleteCard', id)
        })
      }
    } catch {
      console.log('error: deleteCard')
    }
  },
  async addAnonCard({ commit }, data: ICardData) {
    try {
      commit('setIsSending', true)
      // @ts-ignore
      const res = await this.$repositories.cards.addCard(data, '')
      commit('setNewCard', res.data)

      // @ts-ignore
    } catch (e) {
      console.log(e)
    } finally {
      commit('setIsSending', false)
    }
  },
  async chooseCard({ commit }, id: number) {
    try {
      await this.$repositories.cards.chooseCard(id)
      this.$services.toaster.add({
        type: 'success',
        title: '',
        description: 'Выбрана карта по умолчанию',
      })
    } catch {
      console.log('error: chooseCard')

      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Ошибка при выборе карты',
      })
    }
  },
  async checkCloudCard({ commit }, data: { [key: string]: string }) {
    const res = await this.$services.checkout.createCryptogramPersonal(data)

    if (res.success) {
      commit('setSuccess', true)
      commit('setPacket', res.packet)
    }

    if (res.success === false) {
      commit('setSuccess', false)
      if (res.messages.cardNumber) {
        commit('setCardNumberInvalid', res.messages.cardNumber)
      }
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  cards(state) {
    return state.cards
  },
  newCard(state) {
    return state.newCard
  },
  isSending(state) {
    return state.isSending
  },
  success(state) {
    return state.success
  },
  errorCardNumberInvalid(state) {
    return state.cardNumberInvalid
  },
  packet(state) {
    return state.packet
  },
}
