import { IHttp } from 'services/common/Http'

export interface IRecipes {
  sendRecipe(recipe: any, orderId: string): Promise<any>
}

export default class Recipes implements IRecipes {
  constructor(private http: IHttp) {}

  async sendRecipe(orderId: string, recipe: any) {
    const fd = new FormData()
    fd.append('order_id', orderId)
    fd.append('file', recipe)

    return await this.http.post('/api-v2/orders/prescription', fd)
  }
}