import { MutationTree, ActionTree, GetterTree } from 'vuex'

export const state = () => ({
  mainPageSeo: {},
  mainPageSeoText: '',
  isMobileApp: false
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setMainPageSeo(state, payload) {
    state.mainPageSeo = payload
  },

  setMainPageSeoText(state, payload) {
    state.mainPageSeoText = payload
  },
  setIsMobileApp(state, payload) {
    state.isMobileApp = payload
  }
}

export const actions: ActionTree<stateT, stateT> = {
  async getMainPage({ commit }) {
    try {
      const res = await this.$repositories.content.findContent('glavnaya', 0, {})
      commit('setMainPageSeo', res.data.data[0].attributes.seo)
      commit('setMainPageSeoText', res.data.data[0].attributes.text)
    } catch (e) {
      console.error('Error getting main page content', e)
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  mainPageSeo(state) {
    return state.mainPageSeo
  },
  mainPageSeoText(state) {
    return state.mainPageSeoText
  },
  isMobileApp(state) {
    return state.isMobileApp
  }
}
