import { IFaqCategories, IFaqAttribute, ICategoryResponse } from 'types/DTO/faq'
import { IMenuObject } from 'types/DTO/menu/index'

export default class FaqService {
  private categoryList = {
    info: 'Информация',
    buyer: 'Покупателю',
    partner: 'Партнеру',
    docs: 'Документы',
  } as { [key: string]: string }

  categories(data: IFaqCategories[]): ICategoryResponse[] {
    const list: ICategoryResponse[] = []

    data.forEach((category: IFaqCategories) => {
      return list.push(this.setCategory(category.attributes))
    })

    return list
  }

  setCategory(category: IFaqAttribute) {
    return {
      id: category.id,
      title: category.title,
      icon: this.setIcon(category.id),
    }
  }

  setIcon(id: number) {
    switch (id) {
      case 2:
        return 'SignQuestionIcon'
      case 5:
        return 'PaymentIcon'
      case 6:
        return 'AvatarIcon'
      default:
        return 'SignQuestionIcon'
    }
  }

  setAboutIcons(data: any): any {
    for (const key in data) {
      for (let i = 0; i < data[key].length; i++) {
        if (!data[key][i].icon) {
          Object.assign(data[key][i], { icon: 'LicenseIcon' })
        }
        Object.assign(data[key][i], { category: this.categoryList[key] })
      }
    }

    return data
  }
}
