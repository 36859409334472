import { IHttp, IHttpParams } from 'services/common/Http'
import { CommonResponse } from 'types/DTO/common/response'
import { IProduct } from 'types/DTO/products'

export interface IProductsFilters {
  getDosage(params: IHttpParams): Promise<any>
  getForm(params: IHttpParams): Promise<any>
}

export default class ProductsFilters implements IProductsFilters {
  constructor(private http: IHttp) {}

  async getDosage(params: IHttpParams): Promise<CommonResponse<IProduct[]>> {
    return await this.http.get('/api-v2/dosage', params)
  }

  async getForm(params: IHttpParams): Promise<CommonResponse<IProduct[]>> {
    return await this.http.get('/api-v2/product-form', params)
  }
}
