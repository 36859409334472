import { IEntityReview } from '~/types/DTO/reviews'

export default class ReviewsAdapter {
  constructor() {}

  adapt(data: IEntityReview[] | IEntityReview): IEntityReview[] {
    const adapted: IEntityReview[] = []

    if (Array.isArray(data)) {
      data.forEach((item: IEntityReview) => {
        return adapted.push(this.adaptItem(item))
      })
    } else {
      Object.assign(adapted, this.adaptItem(data))
    }

    return adapted
  }

  adaptItem(item: any): IEntityReview {
    return {
      id: item.attributes.id,
      user: {
        name: item.attributes.name,
      },
      rate: item.attributes.stars,
      content: item.attributes.text,
      created_at: item.attributes.date,
    }
  }
}
