import { Context } from '@nuxt/types'

export default class LocalStorageBasket {
  constructor(private $store: Context['store']) {}

  setBasketId(id: string) {
    localStorage.setItem('basketId', String(id))
  }

  async getBasketId(storage: string) {
    let basketId = localStorage.getItem(storage)
    const basket = await this.$store.dispatch('rph-basket/getBasketById', basketId)
    if ((basket.error && basket.error.length) || !basketId || basketId === 'null') {
      const res = await this.$store.dispatch('rph-basket/createBasket')
      if (res && res.id) {
        basketId = res.id
        this.setBasketId(String(basketId))
      }
    }
    this.$store.commit('rph-basket/setBasketId', basketId)
    return basketId
  }

  getBasketByKey(storage: string): string {
    return window.localStorage.getItem(storage) as string
  }

  async deleteBasket(id: string) {
    await this.$store.dispatch('rph-basket/deleteBasket', id)
    await this.$store.dispatch('rph-basket/updateBasket')
    localStorage.removeItem('basketId')
    localStorage.removeItem('basketProductsIds')
    this.$store.commit('rph-basket/deleteBasket')
  }

  clearBasket() {
    localStorage.removeItem('basketId')
    this.$store.commit('rph-basket/deleteBasket')
  }
}
