import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { INotification } from 'types/DTO/notification/index'

export const state = () => ({
  nameError: '',
  emailError: '',
  success: false,
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setNameError(state, payload) {
    state.nameError = payload
  },
  setEmailError(state, payload) {
    state.emailError = payload
  },
  setSuccess(state, payload) {
    state.success = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async createSubscription({ commit }, data: INotification) {
    try {
      const res = await this.$repositories.notification.createSubscription(data)
      console.log('🐞 ~ file: index.ts ~ line 14 ~ createSubscription ~ res', res)
      commit('setSuccess', true)
      this.$services.toaster.add({
        type: 'success',
        title: '',
        description: 'Подписка на уведомления успешно оформлена',
      })
    } catch (e: any) {
      console.log('error in createSubscription', e.response)
      commit('setSuccess', false)
      if (e.response.data.errors && e.response.data.errors.email)
        commit('setEmailError', e.response.data.errors.email[0])

      if (e.response.data.errors && e.response.data.errors.name)
        commit('setNameError', e.response.data.errors.name[0])

      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Что-то пошло не так',
      })
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  nameError(state) {
    return state.nameError
  },
  emailError(state) {
    return state.emailError
  },
  success(state) {
    return state.success
  },
}
