export interface ITextFormat {
  formatText(text: string): string
  formatIncline(number: number, words: Array<string>): string
  formatPhone(phone: string): string
}

export default class TextFormat implements ITextFormat {
  /* ? Удаляем &nbsp; (неразрывный пробел) */

  formatText(text: string) {
    return text.toString()
  }

  /**
   *
   * @name formatIncline
   * @description returns inclined text
   */
  formatIncline(number: number, words: Array<string>): string {
    const value = Math.abs(number) % 100

    const num = value % 10

    if (value > 10 && value < 20) return words[2]

    if (num > 1 && num < 5) return words[1]

    if (num === 1) return words[0]

    return words[2]
  }

  /**
   *
   * @name formatPhone
   * @description returns formatted phone number
   */
  formatPhone(phone: string): string {
    return phone.replace(/[{()},' -]/g, '')
  }
}
