import { IHttp, IHttpResponse } from 'services/common/Http'
import axios from 'axios'
import { getCookie } from '~/utils/getCookie'

const testServer = process.env.BASE_DOMAIN_URL === 'https://dev.aurma.kz'
const stageServer = process.env.BASE_DOMAIN_URL === 'https://stage.aurma.kz'
const prodServer = process.env.BASE_DOMAIN_URL === 'https://aurma.kz'

export interface IAnyQueryMethods {
  sendAnyQueryEvent(operation: string, data: any): any
}

export default class AnyQuery implements IAnyQueryMethods {
  constructor(http: IHttp) {}

  async sendAnyQueryEvent(operation: string, data: any): Promise<any> {
    const sessionId = getCookie('sessionId')
    const userGUID = getCookie('userId')
    const viewGUID = localStorage.getItem('viewId')
    // @ts-ignore
    if (prodServer && sessionId && userGUID && viewGUID) {
      return await axios.post(`https://tracking-app.diginetica.net/event`, {
        eventType: operation,
        apiKey: process.env.DIGINETICA_API_KEY,
        sessionId,
        userGUID,
        viewGUID,
        channel: window.innerWidth < 1000 ? 'WEB_MOBILE' : 'WEB',
        ...data,
      })
    }
  }
}
