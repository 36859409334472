import { IHttpParams, IHttp } from 'services/common/Http'

export interface INews {
  findAll(params: IHttpParams, categoryId: number): Promise<any>
  findBySlug(slug: string, params: IHttpParams): Promise<any>
  getCategories(params: IHttpParams): Promise<any>
}

export default class News implements INews {
  constructor(private $http: IHttp) {}

  async findAll(params: IHttpParams, categoryId: number = -1): Promise<any> {
    if (!categoryId || categoryId === -1) {
      return await this.$http.get('api-v2/news', { params })
    } else {
      return await this.$http.get(`api-v2/news?filter[category_id]=${categoryId}`, { params })
    }
  }

  async findBySlug(slug: string, params: IHttpParams): Promise<any> {
    return await this.$http.get(`api-v2/news/${slug}`, { params })
  }

  async getCategories(params: IHttpParams): Promise<any> {
    return await this.$http.get('api-v2/news/categories', { params })
  }
}
