import { IHttp, IHttpParams, IHttpResponse } from 'services/common/Http'
import { CommonResponse } from 'types/DTO/common/response'
import { IEntityReview, IEntityReviewFull, IReview } from 'types/DTO/reviews'
import { object } from '~/mocks/institutions'
import { res } from '~/mocks/reviews'
import { IInstitution, IInstitutionType } from 'types/DTO/institutions'

export interface IInstitutions {
  findAll(params: IHttpParams): Promise<any>
  getReviews(params: IHttpParams): Promise<any>
  findBySlug(slug: string, params: IHttpParams): Promise<any>
  findTypes(params: IHttpParams): Promise<CommonResponse<IInstitutionType[]>>
  setReview(slug: string, params: IReview): Promise<any>
}

export default class Institutions implements IInstitutions {
  constructor(private http: IHttp) {}

  async findAll(params: IHttpParams): Promise<CommonResponse<IInstitution[]>> {
    // return await new Promise((resolve) => resolve(object.institutions))
    return await this.http.get('/api-v2/medical-institution', params)
  }

  async getReviews(params: IHttpParams): Promise<IEntityReviewFull[]> {
    /* return await this.http.get(`/institutions/${slug}/reviews`, params || {}) */
    // return await this.http.get('/review-api', params || {})
    return await new Promise((resolve) => resolve(res.reviews))
  }

  async findBySlug(
    slug: string,
    params: IHttpParams
  ): Promise<CommonResponse<IInstitution>> {
    return await this.http.get(`/api-v2/medical-institution/${slug}`, params || {})
    // return await new Promise((resolve) =>
    //   resolve(object.institutions.data.filter((i: IInstitution) => i.slug === slug)[0])
    // )
  }

  async findTypes(params: IHttpParams): Promise<CommonResponse<IInstitutionType[]>> {
    return await this.http.get('/api-v2/medical-institution-types', params || {})
  }

  async setReview(slug: string, params: IEntityReview): Promise<any> {
    const formData = new FormData()
    for (const i in params) {
      // @ts-ignore
      formData.append(`Review[${i}]`, params[i as keyof IEntityReview])
    }
    return await this.http.post('/review-api/create/', formData, {})
  }
}
