import { ActionTree, GetterTree, MutationTree } from 'vuex'

export const state = () => ({
  faq: []
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setFaq(state, payload) {
    state.faq = payload
  }
}

export const actions: ActionTree<stateT, stateT> = {
  async getFaqContent({ commit }, params) {
    try {
      const res = await this.$repositories.bonuses.getFaqInfo(params)
      commit('setFaq', res.data.data)
    } catch (e) {
      console.log('error: Faq content', e)
    }
  }
}

export const getters: GetterTree<stateT, stateT> = {
  faqContent(state) {
    return state.faq
  }
}