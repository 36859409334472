export default {
  async getNews({ commit }, { page }) {
    try {
      const newsReq = await this.$axios.$get('/news-api/', {
        params: {
          page: parseInt(page),
          sort: '-created_at',
        },
      })
      commit('SET_NEWS', newsReq)
    } catch (e) {
      throw new Error(`Ошибка получения новостей: ${JSON.stringify(e)}`)
    }
  },
  async getNewsSlug({ commit }, { id }) {
    try {
      const newsReq = await this.$axios.$get(`/news-api/view/?id=${id}`)
      return newsReq.data
    } catch (e) {
      throw new Error(`Ошибка получения новостей: ${JSON.stringify(e)}`)
    }
  },
  async getBreadcrumbs({ commit }, slug) {
    try {
      const breadcrumbsReq = await this.$axios.$get(`/breadcrumbs-api/news/?slug=${slug}`)
      return breadcrumbsReq
    } catch (e) {
      throw new Error(`Ошибка получения хлебных крошек новостей: ${JSON.stringify(e)}`)
    }
  },
}
