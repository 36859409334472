import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IInn } from 'types/DTO/inns'
import { IMetaData } from 'types/DTO/common/response'
import { IHttpParams } from 'services/common/Http'

export const state = () => ({
  inns: [] as IInn[],
  innBySlug: {} as IInn,
  innsPageSeo: {},
  innsPageSeoText: '',
  meta: {} as IMetaData,
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setInns(state, payload) {
    state.inns = payload
  },
  setInnBySlug(state, payload) {
    state.innBySlug = payload
  },
  setInnsPageSeo(state, payload) {
    state.innsPageSeo = payload
  },
  setInnsPageSeoText(state, payload) {
    state.innsPageSeoText = payload
  },
  setMeta(state, payload) {
    state.meta = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async getInns({ commit }, params: IHttpParams) {
    try {
      const res = await this.$repositories.inns.findAll(params)
      console.log('inns')
      // @ts-ignore
      commit('setInns', res.data.data)
      // @ts-ignore
      commit('setMeta', res.data.meta)
    } catch {
      console.log('error: getInns')
    }
  },

  async getInnBySlug({ commit }, { slug, params }) {
    try {
      const res = await this.$repositories.inns.findBySlug(slug, params)
      commit('setInnBySlug', res.data)
    } catch {
      console.log('error: getInnBySlug')
    }
  },

  async getInnsPage({ commit }) {
    try {
      const res = await this.$repositories.content.findContent('mnn', 0, {})
      commit('setInnsPageSeo', res.data.data[0].attributes.seo)
      commit('setInnsPageSeoText', res.data.data[0].attributes.text)
    } catch (e) {
      console.error('Error getting main page content', e)
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  inns(state) {
    return state.inns
  },
  innBySlug(state) {
    return state.innBySlug
  },
  innsPageSeo(state) {
    return state.innsPageSeo
  },
  innsPageSeoText(state) {
    return state.innsPageSeoText
  },
  meta(state) {
    return state.meta
  },
}
