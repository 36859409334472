export default ({ app }) => {
  if (process.client) {
    window.onNuxtReady(() => {
      document.addEventListener('click', function (event) {
        const link = event.target.closest('a')
        if (link) {
          const linkUrl = link.href
          const linkDomain = new URL(linkUrl).hostname
          const currentDomain = window.location.hostname

          if (linkDomain !== currentDomain) {
            const linkClasses = link.className
            const linkId = link.id
            const outbound = true

            app.$gtm.push({
              event: 'page_view',
              link_classes: linkClasses,
              link_domain: linkDomain,
              link_id: linkId,
              link_url: linkUrl,
              outbound,
            })
          }
        }
      })
    })
  }
}
