import { IProductAnnotation } from 'types/DTO/products/index'
import { IBasketProduct } from 'types/DTO/basket'

export default class ProductHandler {
  mapDropDownSpecs(specs: IProductAnnotation) {
    return {
      description: {
        id: 1,
        title: 'Описание',
        hash: 'description',
        content: specs.description,
      },
      pharmgroup: {
        id: 2,
        title: 'Фармакотерапевтическая группа',
        hash: 'pharmgroup',
        content: specs.pharmgroup,
      },
      composition: {
        id: 3,
        title: 'Состав',
        hash: 'composition',
        content: specs.composition,
      },
      use_cases: {
        id: 4,
        title: 'Показания',
        hash: 'use_cases',
        content: specs.use_cases,
      },
      overdose: {
        id: 5,
        title: 'Передозировка',
        hash: 'overdose',
        content: specs.overdose,
      },
      releaseform: {
        id: 6,
        title: 'Форма выпуска и описание',
        hash: 'releaseform',
        content: specs.releaseform,
      },
      specialinstructions: {
        id: 7,
        title: 'Особые указания',
        hash: 'specialinstructions',
        content: specs.specialinstructions,
      },
      applications: {
        id: 8,
        title: 'Рекомендации по применению',
        hash: 'application',
        content: specs.application,
      },
      contraindications: {
        id: 9,
        title: 'Противопоказания',
        hash: 'contraindications',
        content: specs.contraindications,
      },
      pharmacology: {
        id: 10,
        title: 'Фармакология',
        hash: 'pharmacology',
        content: specs.pharmacology,
      },
      interaction: {
        id: 11,
        title: 'Взаимодействия',
        hash: 'interaction',
        content: specs.drug_interactions,
      },
      side_effects: {
        id: 12,
        title: 'Побочные действия',
        hash: 'side_effects',
        content: specs.side_effects,
      },
      /* No field in api */
      conditions: {
        id: 13,
        title: 'Условия хранения',
        hash: '#',
        content: specs.storage_condition,
      },
    }
  }

  recipes(products: IBasketProduct[]): any {
    const recipes = products.filter((item: IBasketProduct) => item.is_recipe !== false)

    return recipes.map((item: IBasketProduct) => ({
      id: item.id,
      image: item.images[0].links.raw,
      title: item.title,
      sub_title: `${item.mnn} ${item.dosage}`,
    }))
  }
}
