import { IHttpParams, IHttp, IHttpResponse } from 'services/common/Http'
import { CommonResponse } from 'types/DTO/common/response'
import { IProduct } from 'types/DTO/products'
import { IBasketId, IBasketProduct } from 'types/DTO/basket'
import { object } from '~/mocks/products'

export interface IBasket {
  createBasket(): Promise<IHttpResponse<CommonResponse<IBasketId>>>
  findAll(id: number, params: IHttpParams): Promise<CommonResponse<any>>
  deleteBasketItem(
    id: number,
    product_id: number,
    pharmacy_id: number,
    params: IHttpParams
  ): Promise<any>
  addToBasket(params: IBasketProduct): Promise<any>
  changeCount(
    id: number,
    product_id: number,
    pharmacy_id: number,
    params: IHttpParams
  ): Promise<IHttpResponse<CommonResponse<IBasketProduct>>>
  deleteBasket(id: number): Promise<any>
  concatBasket(id: number, params: IHttpParams): Promise<any>
}

export default class Basket implements IBasket {
  constructor(private $http: IHttp) {}

  async createBasket(): Promise<IHttpResponse<CommonResponse<IBasketId>>> {
    return await this.$http.post('/api-v2/basket', {})
  }

  async findAll(id: number, params: IHttpParams): Promise<CommonResponse<IProduct[]>> {
    return await this.$http.get(`/api-v2/basket/${id}`, params || {})
    // return await new Promise((resolve) => resolve(object.products))
  }

  async deleteBasketItem(
    id: number,
    product_id: number,
    pharmacy_id: number,
    params: IHttpParams
  ): Promise<any> {
    return await this.$http.delete(
      `/api-v2/basket/${id}/products/${product_id}/pharmacy/${pharmacy_id}`,
      params || {}
    )
    // return await new Promise((resolve) =>
    //   resolve(object.products.data.filter((item: any) => item.id !== product_id))
    // )
  }

  async deleteBasket(id: number): Promise<any> {
    return await this.$http.delete(`/api-v2/basket/${id}`)
  }

  async concatBasket(id: number, params: IHttpParams): Promise<any> {
    return await this.$http.get(`/api-v2/basket/concat/${id}`, params)
  }

  async addToBasket(params: IBasketProduct): Promise<any> {
    const formData = new FormData()
    for (const key in params) {
      formData.append(key, String(params[key as keyof IBasketProduct]))
    }
    return await this.$http.post(`/api-v2/basket/products`, formData)
  }

  async changeCount(
    id: number,
    product_id: number,
    pharmacy_id: number,
    params: IHttpParams
  ): Promise<any> {
    return await this.$http.put(
      `/api-v2/basket/${id}/products/${product_id}/pharmacy/${pharmacy_id}`,
      params
    )
  }
}
