import { IPharmacy, IPharmacySchedule, IPharmacyScheduleTime } from 'types/DTO/pharmacies'

export interface IBasketSchedule {
  isPharmacyOpen(pharmacyData: IPharmacy): boolean
  subtractThirtyMinutes(time: string): string | undefined
  calcCurrentScheduleTime(currentSchedule: any): any
}

export default class BasketSchedule implements IBasketSchedule {
  isPharmacyOpen(pharmacyData: IPharmacy): boolean {
    if (pharmacyData && pharmacyData.schedule) {
      const currentDay = new Date().getDay()
      const currentHour = new Date().getHours()
      const currentMinute = new Date().getMinutes()

      const currentSchedule = pharmacyData?.schedule.find(
        (item: IPharmacySchedule) => item.day === currentDay,
      )

      if (!currentSchedule) {
        return true
      } else {
        const [startHour, startMinute] = currentSchedule.work_time
          .split('-')[0]
          .split(':')
          .map(Number)
        let [endHour, endMinute] = currentSchedule.work_time
          .split('-')[1]
          .split(':')
          .map(Number)

        if (endHour < startHour || (endHour === startHour && endMinute < startMinute)) {
          endHour += 24
        }

        if (
          currentHour < startHour ||
          currentHour > endHour ||
          (currentHour === endHour && currentMinute > endMinute)
        ) {
          return false
        }

        const minutesUntilClose =
          endHour * 60 + endMinute - (currentHour * 60 + currentMinute)
        return minutesUntilClose > 30
      }
    }
    return true
  }

  subtractThirtyMinutes(time: string) {
    if (time) {
      let [hours, minutes] = time.split(':').map(Number)
      minutes -= 30
      if (minutes < 0) {
        hours -= 1
        minutes += 60
      }
      if (hours < 0) hours += 24
      return `${(hours % 24).toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
    }
  }

  calcCurrentScheduleTime(currentSchedule: any) {
    const pharmacyWorkTime: IPharmacyScheduleTime = {
      startTime: '',
      endTime: '',
    }

    if (!currentSchedule) {
      return {}
    } else {
      pharmacyWorkTime.startTime = currentSchedule.work_time.split('-')[0]
      pharmacyWorkTime.endTime = this.subtractThirtyMinutes(
        currentSchedule.work_time.split('-')[1],
      )
    }

    return pharmacyWorkTime
  }
}