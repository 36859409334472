export default () => ({
  reviewsList: [],
  reviewsInfo: {
    links: {
      self: '',
      first: '',
      last: '',
      next: '',
    },
    meta: {
      'total-count': '',
      'page-count': '',
      'current-page': '',
      'per-page': '',
    },
  },
})
