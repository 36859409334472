import { IImages } from 'types/DTO/common/image'

export enum Types {
  small = 'small',
  large = 'large',
}

export interface IBannerLink {
  external: boolean
  link: string
}

export interface IBanner {
  id: number
  type: Types
  images?: IImages
  title: string
  description?: string
  link: IBannerLink
  is_active: boolean
  location: string
}
