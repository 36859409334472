import { IHttp, IHttpParams, IHttpResponse } from 'services/common/Http'

export interface IContent {
  findContent(slug: string, id: number, params: IHttpParams): Promise<any>
}

export default class Content implements IContent {
  constructor(private $http: IHttp) {}

  async findContent(slug: string, id: number, params: IHttpParams): Promise<any> {
    return await this.$http.get(`/content-api/?slug=${slug}`, params)
  }
}
