import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IGeocoderAddress } from 'types/DTO/geocoder'

export const state = () => ({
  address: {} as IGeocoderAddress,
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setAddress(state, payload) {
    state.address = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async getAddressByCoords({ commit }, coords: string) {
    try {
      const res = await this.$repositories.geocoder.getAddressByCoords(coords)
      const address = this.$services.geocoder.adapt(
        res.data.response.GeoObjectCollection.featureMember[0]
      )
      commit('setAddress', address)
    } catch {
      console.log('error: getAddressByCoords')
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  address(state) {
    return state.address
  },
}
