import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IUser, IUserData } from 'types/DTO/users'

export const state = () => ({
  user: {} as IUser,
  isUploading: false,
  isSending: false,
  editProfileErrors: {} as { [key: string]: string },
  preRegError: false as boolean,
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setUser(state, payload) {
    state.user = payload
  },
  setUploading(state, payload) {
    state.isUploading = payload
  },
  setSending(state, payload) {
    state.isSending = payload
  },
  setEditProfileErrors(state, payload) {
    state.editProfileErrors = payload
  },
  resetServerError(state, payload) {
    const { key, value } = payload
    state.editProfileErrors[key] = value
  },

  setPreRegError(state, payload) {
    state.preRegError = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async signUp({ commit }, data: IUserData) {
    try {
      // @ts-ignore
      const res = await this.$repositories.users.signUp(data, {})
      commit('setUser', res.data)
      console.log('user added')
      return res
      // @ts-ignore
    } catch (e) {
      console.log(e.response)
      throw e
    }
  },
  async preRegistration({ commit }, data: IUserData) {
    try {
      // @ts-ignore
      const res = await this.$repositories.users.preRegistration(data, {})
      commit('setUser', res.data)
      console.log('user pre-registration succeed')
    } catch (e) {
      console.log(e)
    }
  },

  async checkUser({ commit }, params: Promise<any>) {
    try {
      // @ts-ignore
      const res = await this.$repositories.users.checkUser(params)
      commit('setPreRegError', !!res.data.error)
      return res.data
    } catch (e) {
      console.log(e)
    }
  },

  async getUser({ commit }, { id, params }) {
    try {
      const { data } = await this.$repositories.users.findUser(id, params)
      const res = this.$services.user.adaptUser(data)

      commit('setUser', res)
    } catch (e) {
      console.log(e)
    }
  },

  async changeAvatar({ commit }, { id, avatar }) {
    try {
      commit('setUploading', true)
      await this.$repositories.users.changeAvatar(id, avatar)

      this.$services.toaster.add({
        type: 'success',
        title: '',
        description: 'Фотография успешно добавленна',
      })
    } catch (e) {
      console.log(e)

      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Произошла ошибка',
      })
    } finally {
      commit('setUploading', false)
    }
  },

  async editUser({ commit, state }, { id, data }) {
    try {
      const res = await this.$repositories.users.editUser(id, data)

      commit('setSending', true)

      commit('setUser', this.$services.user.adaptUser(res.data))

      this.$services.toaster.add({
        type: 'success',
        title: '',
        description: 'Профиль успешно обновлен!',
      })
    } catch (e: any) {
      commit('setSending', false)
      const errors = e.response.data ?? 0
      for (let i = 0; i < errors.length; i++) {
        const element = errors[i]

        commit('setEditProfileErrors', {
          ...state.editProfileErrors,
          [element.field]: element.message,
        })
      }

      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Произошла ошибка обновления профиля!',
      })
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  user(state) {
    return state.user
  },
  status(state) {
    return state.isUploading
  },
  statusSubmit(state) {
    return state.isSending
  },
  serverEditErrors(state) {
    return state.editProfileErrors
  },

  preRegError(state) {
    return state.preRegError
  },
}
